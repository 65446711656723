import { defineStore } from "pinia";
import { IPagination, ResponseCommonUsers } from "@/types/common/api";
import {
  GetBookingResponse,
  GetCalendarResponse,
  PlanningTripsResponse,
} from "@/types/response/planning";
import { PlanningTripAPI } from "@/api/planning";
import { CustomerSeating, GetBookingRequest } from "@/types/request/planning";
import { UserApi } from "@/api/users";

export type STEP = "PASSENGERS" | "PAYMENT" | "CONFIRM" | "EMPTY";
export type STAGE_STEPS = "outbound" | "return";

interface PlanningStateStore {
  planningTrips: PlanningTripsResponse[];
  totalPlanningTrips: number;
  paginationPlanningTrips: IPagination;
  tripsSelected: GetBookingResponse[];
  tripsSelectedFinal: GetBookingResponse[];
  searchSelected?: GetBookingRequest;
  tripSelected?: GetBookingResponse;
  tripFinalSelected?: GetBookingResponse;
  passengers: CustomerSeating[];
  passengersFinal?: CustomerSeating[];
  owner?: CustomerSeating;
  ownerFinal?: CustomerSeating;
  step: STEP;
  calendarData: GetCalendarResponse[];
  drivers: ResponseCommonUsers[];
  isLoadingBooking: boolean;
  emptyTrips?: boolean;
  stageTrip: STAGE_STEPS;
  stageSeatings: STAGE_STEPS
}

const planningTripAPI = new PlanningTripAPI();
const userApi = new UserApi();

export const usePlanningStore = defineStore("planning", {
  // other options...
  state: (): PlanningStateStore => ({
    planningTrips: [],
    totalPlanningTrips: 0,
    paginationPlanningTrips: {
      limit: 5,
      page: 1,
    },
    tripsSelected: [],
    tripsSelectedFinal: [],
    searchSelected: undefined,
    tripSelected: undefined,
    tripFinalSelected : undefined,
    passengers: [],
    owner: undefined,
    step: "EMPTY",
    calendarData: [],
    drivers: [],
    isLoadingBooking: false,
    emptyTrips: undefined,
    stageTrip: "outbound",
    stageSeatings: 'outbound',
    passengersFinal : [],
    ownerFinal : undefined
  }),
  actions: {
    updateStageTrip(stage: STAGE_STEPS = 'outbound') {
      this.stageTrip = stage;
    },
    updateStageSeating(stage : STAGE_STEPS ='outbound'){
      this.stageSeatings = stage
    },
    async getDrivers() {
      const data = await userApi.getAllDrivers();
      this.drivers = data.map((user) => {
        return {
          ...user,
          title: user.firstName + " " + user.lastName,
          value: user._id,
        };
      });
    },
    async getPlanningTrips() {
      const { items, total } = await planningTripAPI.getPlanningTrips({
        limit: this.paginationPlanningTrips.limit,
        page: this.paginationPlanningTrips.page,
      });
      this.planningTrips = items.map((item) => {
        return {
          ...item,
          driver: {
            ...item.driver,
            newValueEditable: item.driver._id,
          },
          allDays: false,
          nameCRUD: {
            newValueEditable: item.name,
            editable: false,
            loading: false,
          },
          stations:item.stations.map((station) => ({
            ...station,
            hourInitCrud: {
              editable: false,
              newValueEditable: station.hourInit,
              loading: false,
            },
          })),
        };
      });
      this.totalPlanningTrips = total;
    },
    orderPrice() {
      this.tripsSelected = this.tripsSelected.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          return a.price - b.price;
        }
      );
    },
    orderTime(early = true) {
      this.tripsSelected = this.tripsSelected.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          const hourA = a.hourInit;
          const hourB = b.hourInit;
          if (hourA < hourB) {
            return early ? -1 : 1;
          }
          if (hourA > hourB) {
            return early ? 1 : -1;
          }
          return 0;
        }
      );
    },
    setPage(page: number) {
      this.paginationPlanningTrips.page = page;
    },
    setLimit(limit: number) {
      this.paginationPlanningTrips.limit = limit;
    },
    async getTripsBooking(data: GetBookingRequest) {
      this.searchSelected = {...data};
      localStorage.setItem("searchSelected", JSON.stringify(data));
      const [trips, tripsFinal] = await Promise.all([
        planningTripAPI.getBooking(data),
        data.dateFinal
          ? planningTripAPI.getBooking({
              ...data,
              origin: data.destination,
              destination: data.origin,
              date: data.dateFinal,
            })
          : [],
      ]);
      this.tripsSelectedFinal = tripsFinal.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          const hourA = a.hourInit;
          const hourB = b.hourInit;
          if (hourA < hourB) {
            return -1;
          }
          if (hourA > hourB) {
            return 1;
          }
          return 0;
        }
      );
      this.tripsSelected = trips.sort(
        (a: GetBookingResponse, b: GetBookingResponse) => {
          const hourA = a.hourInit;
          const hourB = b.hourInit;
          if (hourA < hourB) {
            return -1;
          }
          if (hourA > hourB) {
            return 1;
          }
          return 0;
        }
      );
      this.emptyTrips = this.tripsSelected.length === 0;
    },
    clearBooking() {
      this.tripsSelected = [];
      this.tripsSelectedFinal = [];
      this.searchSelected = undefined;
      localStorage.removeItem("tripSelected");
      localStorage.removeItem("tripFinalSelected");
      localStorage.removeItem("searchSelected");
      localStorage.removeItem("owner");
      localStorage.removeItem("passengers");
      localStorage.removeItem("passengersFinal");
      localStorage.removeItem("step");
      localStorage.removeItem('ownerFinal')
      this.$reset();
    },
    setLoadingBooking(value: boolean) {
      this.isLoadingBooking = value;
    },
    removeSearchBooking() {
      this.searchSelected = undefined;
      this.tripsSelected = [];
    },
    setTrip(trip: GetBookingResponse) {
      if (this.stageTrip == "return") {
        this.tripFinalSelected = {...trip};
        localStorage.setItem("tripFinalSelected", JSON.stringify(trip));
      } else if (this.stageTrip == "outbound" && trip) {
        this.tripSelected = {...trip};
        localStorage.setItem("tripSelected", JSON.stringify(trip));
      }
    },
    removeTripSelected() {
      this.tripSelected = undefined;
    },
    setOwner(data: CustomerSeating) {
      if(this.stageSeatings==='outbound'){
        localStorage.setItem("owner", JSON.stringify(data));
        this.owner = {...data};
      }else{
        localStorage.setItem("ownerFinal", JSON.stringify(data));
        this.ownerFinal = {...data}
      }
      
      
    },
    setPassengers(passengers: CustomerSeating[]) {
      if(this.stageSeatings == 'outbound'){
        localStorage.setItem("passengers", JSON.stringify(passengers));
        this.passengers = [...passengers];
        return;
      }
      localStorage.setItem("passengersFinal", JSON.stringify(passengers));
      this.passengersFinal = [...passengers];
    },
    setStep(step: STEP) {
      this.step = step;
      localStorage.setItem("step", step);
      if (step == "PAYMENT") {
        localStorage.setItem("passengers", JSON.stringify(this.passengers));
        localStorage.setItem("owner", JSON.stringify(this.owner));
      }
    },
    setLoadingPlanning(id: string, value: boolean) {
      this.planningTrips = this.planningTrips.map((planning) => {
        return {
          ...planning,
          loading: planning._id == id ? value : false,
        };
      });
    },
    async getCalendar(id: string) {
      const data = await planningTripAPI.getCalendar(id);
      this.calendarData = data;
    },
    resetCalendar() {
      this.calendarData = [];
    },
    resetPlannings() {
      this.planningTrips = [];
      this.setPage(1);
      this.setLimit(5);
    },
    setAllDays(id: string, value: boolean) {
      this.planningTrips = this.planningTrips.map((planning) => {
        return {
          ...planning,
          allDays: planning._id === id ? value : planning.allDays,
        };
      });
    },
    updateDriverCRUD(
      id: string,
      action: "reset" | "editable" | "loading" = "editable",
      value?: boolean
    ) {
      this.planningTrips = this.planningTrips.map((planning) => {
        if (planning._id === id) {
          const driverPlanning = planning.driver;
          switch (action) {
            case "editable":
              driverPlanning.editable = true;
              break;
            case "reset":
              driverPlanning.editable = false;
              driverPlanning.newValueEditable = driverPlanning._id;
              break;
            case "loading":
              driverPlanning.loading = value;
              break;
            default:
              break;
          }
          return {
            ...planning,
            driver: driverPlanning,
          };
        }
        return { ...planning };
      });
    },
    resetSearchTrip() {
      this.updateStageSeating('outbound')
      this.updateStageTrip('outbound')
      this.tripSelected = undefined;
      this.tripFinalSelected = undefined;
    },
    updateNameCRUD(
      id: string,
      action: "reset" | "editable" | "loading" = "editable",
      value?: boolean
    ) {
      this.planningTrips = this.planningTrips.map((planning) => {
        if (planning._id === id) {
          const nameCRUD = planning.nameCRUD;
          switch (action) {
            case "editable":
              nameCRUD.editable = true;
              break;
            case "reset":
              nameCRUD.editable = false;
              nameCRUD.newValueEditable = planning.name;
              break;
            case "loading":
              nameCRUD.loading = value;
              break;
            default:
              break;
          }
          return {
            ...planning,
            nameCRUD,
          };
        }
        return { ...planning };
      });
    },
  },
});
