import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    variant: "elevated",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
    color: _ctx.color,
    timeout: _ctx.timeout
  }, _createSlots({
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
      (_ctx.isLink && _ctx.show && _ctx.link)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            size: "x-small",
            variant: "elevated",
            to: _ctx.link,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeNotification()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.messageLink), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.buttonClose)
      ? {
          name: "actions",
          fn: _withCtx(() => [
            _createVNode(_component_v_btn, {
              style: {"text-transform":"capitalize"},
              onClick: _ctx.closeNotification
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Cerrar ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "color", "timeout"]))
}