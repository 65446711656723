
  import { useCouponStore } from "@/store/modules/coupon";
  import { getErrorCatch, formatCurrency } from "@/utils";
  import { Options, Vue } from "vue-class-component";
  import type { Header, ServerOptions } from "vue3-easy-data-table";
  import { formatDateCompleted, getDate } from "@/filters/formatDate";
  import ModalCommon from "../common/ModalCommon.vue";
  import { CouponTripResponse, CouponsResponse } from "@/types/response/coupon";
  import { CouponApi } from "@/api/coupons";
  @Options({
    name: "TableCoupons",
    components: {
      ModalCommon,
    },
  })
  export default class TableCoupons extends Vue {
    private couponStore = useCouponStore();
    private couponApi = new CouponApi();
    public loadingTable = false;
    public formatDateCompleted = formatDateCompleted;
    public formatCurrency = formatCurrency;
    public isVisibleModalDelete = false;
    public loadingDelete = false;
    public cuponSelected: CouponsResponse | null = null;
    public nameQuery = "";
    public finalNameQuery = "";
    public tripsSelect: CouponTripResponse[] = [];
    public modalTrips = false;
    public getDate = getDate;
  
    public serverOptions: ServerOptions = {
      page: 1,
      rowsPerPage: 5,
    };
  
    get total() {
      return this.couponStore.totalCoupons;
    }
  
    get items() {
      return this.couponStore.coupons;
    }
  
    public headers: Header[] = [
      { text: "Nombre", value: "name" },
      { text: "Limite", value: "isInfinite" },
      { text: "Valor", value: "type" },
      { text: "Usos", value: "uses" },
      { text: "Monto mínimo", value: "minAmount" },
      { text: "", value: "actions" },
    ];
  
    public headersTrip: Header[] = [
      { text: "Código", value: "code" },
      {text : 'Fecha de creación' , value: 'dateCreated'},
      { text: "Fecha", value: "dateExecution" },
      { text: "Tipo", value: "typeTrip" },
    ];
  
    created() {
      this.$watch("serverOptions", ({ page, rowsPerPage }: ServerOptions) => {
        this.couponStore.setPage(page);
        this.couponStore.setLimit(rowsPerPage);
        this.getCoupons();
      });
    }
  
    mounted(): void {
      this.getCoupons();
    }
  
    async getCoupons() {
      this.loadingTable = true;
      try {
        await this.couponStore.getCoupons();
      } catch (error) {
        getErrorCatch(error);
      }
      this.loadingTable = false;
    }
  
    openModalDeleteCoupon(_id: string) {
      this.cuponSelected = this.items.find((item) => item._id === _id) || null;
      if (!this.cuponSelected) return;
      this.isVisibleModalDelete = true;
    }
  
    async deleteCoupon() {
      if (!this.cuponSelected) return;
      this.loadingDelete = true;
      try {
        await this.couponApi.deleteCoupon(this.cuponSelected._id);
        await this.getCoupons();
        this.closeModalDeleteCoupon();
      } catch (error) {
        getErrorCatch(error);
      }
      this.loadingDelete = false;
    }
  
    closeModalDeleteCoupon() {
      this.isVisibleModalDelete = false;
      this.cuponSelected = null;
    }
    removeFullName() {
      this.finalNameQuery = "";
      this.couponStore.setName("");
      this.getCoupons();
    }
    setNameCoupon() {
      console.log(this.nameQuery);
      if (this.nameQuery == null) return;
      this.couponStore.setName(this.nameQuery);
      this.finalNameQuery = this.nameQuery;
      this.nameQuery = "";
      this.getCoupons();
    }
    openTripModal(id: string) {
      const coupon = this.items.find(({ _id }) => _id === id);
      try {
        if (coupon) {
          const trips = coupon.orderUses;
          this.tripsSelect = trips;
          this.modalTrips = true;
        } else {
          throw new Error("Cupon no existe");
        }
      } catch (error) {
        getErrorCatch(error);
      }
    }
  
    closeModalTrip() {
      this.modalTrips = false;
      this.tripsSelect = [];
    }
  }
  