import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "text-helvetica h-regular",
  style: {"color":"#4C4C4B"}
}
const _hoisted_2 = { class: "text-primary text-decoration-underline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_EasyDataTable = _resolveComponent("EasyDataTable")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mt-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_EasyDataTable, {
            loading: _ctx.loadingTable,
            headers: _ctx.headers,
            items: _ctx.items,
            "server-items-length": _ctx.total,
            "server-options": _ctx.serverOptions,
            "onUpdate:serverOptions": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.serverOptions) = $event)),
            "buttons-pagination": ""
          }, {
            "item-dateCreated": _withCtx(({ dateCreated }) => [
              _createTextVNode(_toDisplayString(_ctx.formatDateCompleted(dateCreated)), 1)
            ]),
            "item-actions": _withCtx((vehicle) => [
              _createVNode(_component_v_btn, {
                icon: "mdi-delete",
                color: "red",
                size: "x-small",
                variant: "text",
                class: "ml-1",
                onClick: ($event: any) => (_ctx.openModalDeleteVehicle(vehicle))
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["loading", "headers", "items", "server-items-length", "server-options"])
        ]),
        _: 1
      }),
      _createVNode(_component_ModalCommon, {
        "max-width": "600",
        textButton: "Eliminar",
        onEventSuccess: _ctx.deleteVehicle,
        isVisible: _ctx.isVisibleModalDelete,
        title: "Eliminar vehículo",
        loading: _ctx.loadingDelete,
        onCloseModal: _ctx.closeModalDelete
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            _createTextVNode("¿Estas seguro de eliminar el vehículo con patente "),
            _createElementVNode("b", _hoisted_2, _toDisplayString(_ctx.vehicleSelected?.patent), 1),
            _createTextVNode("?")
          ])
        ]),
        _: 1
      }, 8, ["onEventSuccess", "isVisible", "loading", "onCloseModal"])
    ]),
    _: 1
  }))
}