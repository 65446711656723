
import { getErrorCatch } from '@/utils';
import { UserApi } from '@/api/users';
import { Options, Vue } from 'vue-class-component';
import { GetUsersAutocomplete } from '@/types/response/guest'
import { VehicleAPI } from '@/api/vehicles';
import { VehiclePatentResponse } from '@/types/response/vehicle';
import { RouteStationAPI } from '@/api/route-station';
import { GetRouteByIDResponse, GetRoutesResponse } from '@/types/response/route';
import { CreatedPlanningTrip, CreatedPlanningTripStation, CreatedTripStationPlanning } from '@/types/request/planning';
import { requiredField, generateListDates, } from '@/utils';
import { FormVuetify } from '@/types/common/vuetify'
import { PlanningTripAPI } from '@/api/planning'
import { useMainStore } from '@/store/modules/main';
import { convertTo12HourFormat, formatDateNoonJSDate, formatDateStartJSDate } from '@/filters/formatDate';

@Options({
  name: 'PlanningTrip',
  components: {
  }
})
export default class PlanningTrip extends Vue {
  public loadingDriver = false;
  private userApi = new UserApi();
  private vehicleApi = new VehicleAPI();
  private planningTripAPI = new PlanningTripAPI();
  private routeStationAPI = new RouteStationAPI();
  public mainStore = useMainStore();
  public isRange = true;
  public loadingVehicle = false;
  public loadingRoute = false;
  public loadingCreatePlanning = false;
  public search = '';
  public searchVehicle = '';
  public convertTo12HourFormat = convertTo12HourFormat;
  public searchRoute = '';
  public dataCreatePlanning: CreatedPlanningTrip = {
    driver: undefined,
    vehicle: undefined,
    route: undefined,
    days: [],
    name: '',
    isRange: true
  }
  public items: GetUsersAutocomplete[] = []
  public vehicles: VehiclePatentResponse[] = []
  public routes: GetRoutesResponse[] = []
  public routeSelected: GetRouteByIDResponse | null = null;
  public dateCreatedPlanningStations: CreatedPlanningTripStation | null = null;
  public errorDate = false;
  public invalidStations = false;

  public requiredField = requiredField;

  created(): void {
    this.$watch('search', (val: string) => {
      val && val !== this.dataCreatePlanning.driver && this.querySelections(val)
    })
    this.$watch('searchVehicle', (val: string) => {
      val && val !== this.dataCreatePlanning.vehicle && this.getVehicles(val)
    })
    this.$watch('searchRoute', (val: string) => {
      val && val !== this.dataCreatePlanning.route && this.getRoutes(val)
    })
  }

  async querySelections(val: string) {
    this.loadingDriver = true;
    try {
      this.items = await this.userApi.getDriversByName(val);
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingDriver = false;
  }

  async getVehicles(patent: string) {
    this.loadingVehicle = true;
    try {
      this.vehicles = await this.vehicleApi.getVehicleByPatent(patent);
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingVehicle = false;
  }

  async getRoutes(name: string) {
    this.loadingRoute = true;
    try {
      this.routes = await this.routeStationAPI.getRoutesByName(name);
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingRoute = false;
  }

  async setRoute(value: string) {
    try {
      this.routeSelected = await this.routeStationAPI.getRoute(value);
      this.dateCreatedPlanningStations = {
        planingTrip: '',
        stations: []
      }
      const stations: CreatedTripStationPlanning[] = []
      for (let index = 0; index < this.routeSelected.stations.length; index++) {
        const { _id, duration, finalStation, keyOrder, originStation } = this.routeSelected.stations[index];
        stations.push({
          durationReal: '',
          hourFinish: '',
          hourInit: '',
          price: '',
          routeStation: _id,
          duration,
          finalStation: finalStation.name,
          keyOrder,
          originStation: originStation.name
        })
      }
      this.dateCreatedPlanningStations.stations = stations;
    } catch (error) {
      getErrorCatch(error)
    }
  }
  stringToHour(hour: string, add: number) {
    const partsHour = hour.split(':');
    if (partsHour.length != 2) return null;
    const dateNow = new Date();
    dateNow.setHours(parseInt(partsHour[0]));
    dateNow.setMinutes(parseInt(partsHour[1]) + add);
    const hourFinal = dateNow.getHours() < 10 ? '0' + dateNow.getHours() : dateNow.getHours();
    const minutesFinal = dateNow.getMinutes() < 10 ? '0' + dateNow.getMinutes() : dateNow.getMinutes();
    return `${hourFinal}:${minutesFinal}`
  }

  formatCL(date: Date) {
    const stringDate = date.toISOString().split("T")[0];
    return new Date(stringDate + "T12:00:00-03:00")
  }

  async createPlanning() {
    this.errorDate = false;
    this.invalidStations = false;
    const form = this.$refs['formInfo'] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (validate.valid) {
      if (!this.dataCreatePlanning.days || this.dataCreatePlanning.days.length === 0) {
        this.errorDate = true;
        return
      }
      if (this.validateStations()) {
        this.loadingCreatePlanning = true;
        try {
          const idPlanningTrip = await this.planningTripAPI.createPlanningTrip({
            ...this.dataCreatePlanning,
            days: this.dataCreatePlanning.days.map((day) => formatDateNoonJSDate(this.formatCL(day)).toJSDate()),
            isRange: this.isRange
          });
          if (this.dateCreatedPlanningStations) {
            this.dateCreatedPlanningStations.planingTrip = idPlanningTrip;
            this.dateCreatedPlanningStations.stations = this.dateCreatedPlanningStations.stations.map((station) => {
              return {
                ...station,
                price: parseFloat(station.price as string),
                durationReal: parseInt(station.durationReal as string)
              };
            })
            await this.planningTripAPI.createDataStationsPlanningTrip(this.dateCreatedPlanningStations);
            this.mainStore.setNotification({ isOpen: true, message: 'Planificación creada', color: 'green darken-2' })
            this.resetData();
          }
        } catch (error) {
          getErrorCatch(error)
        }
        this.loadingCreatePlanning = false;
      } else {
        this.invalidStations = true;
        return;
      }
    } else {
      if (!this.dataCreatePlanning.days || this.dataCreatePlanning.days.length === 0) {
        this.errorDate = true;
        return
      }
    }
  }

  resetData() {
    this.dataCreatePlanning = {
      driver: undefined,
      vehicle: undefined,
      route: undefined,
      days: [],
      name: undefined,
      isRange: true
    }
    this.items = []
    this.vehicles = []
    this.routes = []
    this.routeSelected = null
    this.dateCreatedPlanningStations = null
    const form = this.$refs['formInfo'] as FormVuetify;
    if (!form) return;
    form.resetValidation();
  }

  validateStations() {
    let isValid = true;
    if (!this.dateCreatedPlanningStations) {
      return false;
    }
    if (this.dateCreatedPlanningStations.stations.length === 0) {
      return false;
    }
    this.dateCreatedPlanningStations.stations.forEach(({ hourFinish, hourInit, price, durationReal }) => {
      if (!hourFinish || !hourInit || !price) {
        isValid = false;
      }
      if (!durationReal) {
        if (parseInt(durationReal as string) < 0) {
          isValid = false;
        }
      }
    })
    return isValid;
  }
  changeRange() {
    if (!this.isRange) {
      if (this.dataCreatePlanning.days.length == 2) {
        const dateInit = this.dataCreatePlanning.days[0];
        const dateFinish = this.dataCreatePlanning.days[1];
        const dates = generateListDates(formatDateStartJSDate(dateInit).toJSDate(), formatDateStartJSDate(dateFinish).toJSDate());
        this.dataCreatePlanning.days = dates;
      }
    } else {
      this.dataCreatePlanning.days = []
    }
  }
  setDataStation(type: 'duration' | 'hour' | 'price' = 'duration', value: string, idStationCreated: string) {
    if (!this.dateCreatedPlanningStations) return;
    const stationCreated = this.dateCreatedPlanningStations.stations.find(({ routeStation }) => routeStation === idStationCreated)
    if (!stationCreated) return;
    switch (type) {
      case 'duration':
        stationCreated.durationReal = parseInt(value);
        if (stationCreated.hourInit) {
          stationCreated.hourFinish = this.stringToHour(stationCreated.hourInit, parseInt(value)) || ''
        }
        break;
      case 'hour':
        stationCreated.hourInit = value;
        if (stationCreated.durationReal) {
          stationCreated.hourFinish = this.stringToHour(value, parseInt(stationCreated.durationReal as string)) || ''
        }
        break;
      case 'price':
        stationCreated.price = parseFloat(value);
        break;
      default:
        break;
    }
  }
}
