import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import { VEHICLE_ENDPOINTS } from "./endpoints";
import {
  GetAllModelsResponse,
  GetBrandsResponse,
  GetModelByBrandResponse,
  GetTypesVehicleResponse,
  GetVehiclesResponse,
  VehiclePatentResponse,
} from "@/types/response/vehicle";
import { CreateVehicleRequest, GetVehiclesRequest } from "@/types/request/vehicle";

export class VehicleAPI {
  private request = new API(ROUTES_GLOBAL.VEHICLES);
  private api: AxiosInstance = this.request.getInstance();

  async getBrands() {
    const { data } = await this.api.get<GetBrandsResponse[]>(
      VEHICLE_ENDPOINTS.GET_BRANDS
    );
    return data;
  }
  async getAllModels() {
    const { data } = await this.api.get<GetAllModelsResponse[]>(
      VEHICLE_ENDPOINTS.GET_ALL_MODELS
    );
    return data;
  }
  async getModelsByBrand(brand: string) {
    const { data } = await this.api.get<GetModelByBrandResponse[]>(VEHICLE_ENDPOINTS.GET_MODELS + brand);
    return data;
  }
  async getTypesVehicle() {
    const { data } = await this.api.get<GetTypesVehicleResponse[]>(VEHICLE_ENDPOINTS.GET_TYPES_VEHICLE);
    return data;
  }

  async getVehicles({ limit, page }: GetVehiclesRequest) {
    const URL = `${VEHICLE_ENDPOINTS.GET_VEHICLES}?page=${page}&limit=${limit}`;
    const { data } = await this.api.get<GetVehiclesResponse>(URL);
    return data;
  }

  async getVehicleByPatent(patent : string) {
    const URL = `${VEHICLE_ENDPOINTS.GET_VEHICLE_PATENT}${patent}`;
    const { data } = await this.api.get<VehiclePatentResponse[]>(URL);
    return data;
  }

  async createVehicle(data :CreateVehicleRequest){
    await this.api.post(VEHICLE_ENDPOINTS.CREATE_VEHICLE,data);
  }
  
  async deleteVehicle(id : string){
    await this.api.delete(VEHICLE_ENDPOINTS.DELETE_VEHICLE+id)
  }
}
