
import { Options, Vue } from "vue-class-component";
import ModalCommon from "../common/ModalCommon.vue";
import { PlanningTripAPI } from "@/api/planning";
import {
  TripTodayDriverResponse,
  STATUS_ACTIVE_TRIP,
  TypeStatusSeating,
} from "@/types/response/planning";
import { getErrorCatch, translate } from "@/utils";
import { convertTo12HourFormat } from "@/filters/formatDate";
import { DateTime } from "luxon";
import { useMainStore } from "@/store/modules/main";

@Options({
  components: {
    ModalCommon,
  },
})
export default class MainDriver extends Vue {
  private planningApi = new PlanningTripAPI();
  private mainStore = useMainStore();
  public viewSeatings = false;
  public titleViewSeatings = "";
  public tripsToday: TripTodayDriverResponse[] = [];
  public tripsSelected: TripTodayDriverResponse | null = null;
  public seatingsAvailables: TypeStatusSeating[] = [
    "ABSEND",
    "ATTENDED",
    "ON_HOLD",
  ];
  public translate = translate;
  public convertTo12HourFormat = convertTo12HourFormat;
  public loading = false;
  public loadingTrip = false;
  public loadingPDF = false;

  mounted() {
    this.getTripsToday();
  }
  closeViewSeatings() {
    this.viewSeatings = false;
    this.tripsSelected = null;
  }
  openViewSeatings(trip: TripTodayDriverResponse) {
    this.viewSeatings = true;
    this.titleViewSeatings = `Pasajeros de la ruta ${trip.route}`;
    this.tripsSelected = trip;
  }

  getColorStatus(status: TypeStatusSeating) {
    const colors: Record<TypeStatusSeating, string> = {
      ABSEND: "orange",
      ATTENDED: "green",
      POSTPONED: "red",
      ON_HOLD: "grey-darken-4",
    };
    return colors[status] || "grey";
  }

  async changeStatus(id: string, status: TypeStatusSeating) {
    if (this.tripsSelected == null) return;
    try {
      this.tripsSelected.seatings = this.tripsSelected.seatings.map(
        (seating) => {
          if (seating._id == id) {
            seating.loading = true;
          }
          return {
            ...seating,
          };
        }
      );
      await this.planningApi.updateSeatingStatus(id, status);
      this.tripsSelected.seatings = this.tripsSelected.seatings.map(
        (seating) => {
          if (seating._id == id) {
            seating.status = status;
            seating.loading = false;
          }
          return {
            ...seating,
          };
        }
      );
    } catch (error) {
      getErrorCatch(error);
    }
  }

  getColorIcon(status: STATUS_ACTIVE_TRIP) {
    let color = "black";
    switch (status) {
      case STATUS_ACTIVE_TRIP.ON_HOLD:
        color = "grey";
        break;
      case STATUS_ACTIVE_TRIP.IN_PROGRESS:
        color = "green";
        break;
      case STATUS_ACTIVE_TRIP.FINALIZED:
        color = "primary";
        break;
    }
    return `${color} darken-2`;
  }

  getColorTextStatus(status: STATUS_ACTIVE_TRIP) {
    const colorIcon = this.getColorIcon(status);
    const colors = colorIcon.split(" ");
    if (colors.length == 1) return `text-${colors[0]}`;
    return `text-${colors[0]} text-${colors[1]}`;
  }

  getIcon(status: STATUS_ACTIVE_TRIP) {
    switch (status) {
      case STATUS_ACTIVE_TRIP.ON_HOLD:
        return "mdi-bus-clock";
      case STATUS_ACTIVE_TRIP.IN_PROGRESS:
        return "mdi-bus-side";
      case STATUS_ACTIVE_TRIP.FINALIZED:
        return "mdi-handshake";
    }
    return "mdi-account";
  }

  compareStatus(
    a: TripTodayDriverResponse,
    b: TripTodayDriverResponse
  ): number {
    const order: { [key in STATUS_ACTIVE_TRIP]: number } = {
      IN_PROGRESS: 1,
      ON_HOLD: 2,
      FINALIZED: 3,
      POSTPONED: 4,
      REMOVED: 4,
    };
    const Astatus = a.status;
    const Bstatus = b.status;
    return order[Astatus] - order[Bstatus];
  }

  processDate(tripToday: TripTodayDriverResponse) {
    const nowDate = DateTime.local({ zone: this.mainStore.timeZone });
    const origin = tripToday.origin;
    if (!origin) return null;
    const hourMinute = origin.hour.split(":");
    if (hourMinute.length !== 2) return null;
    return DateTime.fromJSDate(nowDate.toJSDate())
      .setZone("America/Santiago")
      .set({
        hour: parseInt(hourMinute[0]),
        minute: parseInt(hourMinute[1]),
        second: 0,
        millisecond: 0,
      });
  }

  sortDescDate(a: TripTodayDriverResponse, b: TripTodayDriverResponse) {
    const originA = this.processDate(a);
    const originB = this.processDate(b);
    if (originA == null) return 0;
    if (originB == null) return 0;
    return originB.toMillis() - originA.toMillis();
  }

  sortDate(a: TripTodayDriverResponse, b: TripTodayDriverResponse) {
    const originA = this.processDate(a);
    const originB = this.processDate(b);
    if (originA == null) return 0;
    if (originB == null) return 0;
    return originA.toMillis() - originB.toMillis();
  }

  async getTripsToday(init = true) {
    if (init) {
      this.loading = true;
    }
    try {
      const nowDate = DateTime.local({ zone: this.mainStore.timeZone });
      const trips = await this.planningApi.getTripsToday();
      const tripsMaped = trips.map((trip) => {
        const initRoute = trip.routes[0] || {};
        const finishRoute = trip.routes[trip.routes.length - 1] || {};
        const hourMinute = initRoute.hourInit.split(":");
        const now = DateTime.fromJSDate(nowDate.toJSDate())
          .setZone("America/Santiago")
          .set({
            hour: parseInt(hourMinute[0]),
            minute: parseInt(hourMinute[1]),
            second: 0,
            millisecond: 0,
          });
        return {
          ...trip,
          finish: {
            hour: finishRoute.hourFinish,
            name: finishRoute.routeStation.finalStation,
          },
          origin: {
            hour: initRoute.hourInit,
            name: initRoute.routeStation.originStation,
          },
          colorStatusIcon: this.getColorIcon(trip.status),
          statusIcon: this.getIcon(trip.status),
          colorStatusText: this.getColorTextStatus(trip.status),
          seatings: trip.seatings,
          dateTimeTrip: now,
        };
      });
      const tripsAvaiable = tripsMaped.filter(
        ({ dateTimeTrip }) => dateTimeTrip.toMillis() > nowDate.toMillis()
      );
      const tripsPast = tripsMaped
        .filter(
          ({ dateTimeTrip }) => dateTimeTrip.toMillis() < nowDate.toMillis()
        )
        .sort(this.sortDescDate);
      this.tripsToday = [...tripsAvaiable, ...tripsPast].sort(
        this.compareStatus
      );
    } catch (error) {
      getErrorCatch(error);
    }
    if (init) {
      this.loading = false;
    }
  }

  getStatus(entryStatus: string) {
    const status: Record<string, string> = {
      ON_HOLD: "En espera",
      IN_PROGRESS: "En progreso",
      FINALIZED: "Finalizado",
    };
    return status[entryStatus] || "";
  }

  setLoadingTrip(id: string, value: boolean) {
    this.tripsToday = this.tripsToday.map((trip) => {
      return {
        ...trip,
        loading: trip._id === id ? value : trip.loading,
      };
    });
  }

  async updateStatusTrip(
    trip: TripTodayDriverResponse,
    status: STATUS_ACTIVE_TRIP
  ) {
    this.setLoadingTrip(trip._id, true);
    try {
      await this.planningApi.updateStatus(trip._id, status);
      await this.getTripsToday(false);
    } catch (error) {
      getErrorCatch(error);
    }
    this.setLoadingTrip(trip._id, false);
  }

  startTrip(trip: TripTodayDriverResponse) {
    this.updateStatusTrip(trip, STATUS_ACTIVE_TRIP.IN_PROGRESS);
  }

  finishTrip(trip: TripTodayDriverResponse) {
    this.updateStatusTrip(trip, STATUS_ACTIVE_TRIP.FINALIZED);
  }

  resetTrip(trip: TripTodayDriverResponse) {
    this.updateStatusTrip(trip, STATUS_ACTIVE_TRIP.ON_HOLD);
  }

  async download(trip: TripTodayDriverResponse) {
    if (!trip) return;
    this.loadingPDF = true;
    try {
      const data = await this.planningApi.getPDF(trip._id);
      const url = URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = url;
      a.download =
        "Calendario_" +
        trip.route +
        "_" +
        trip.dateExecuting.toLocaleString().split("T")[0] +
        ".pdf";
      document.body.appendChild(a);
      a.target = "_blank";
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingPDF = false;
  }
}
