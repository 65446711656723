
import { Vue, Options } from 'vue-class-component';
import { requiredField, validateEmail, getErrorCatch } from '@/utils'
import { useAuthStore } from '../store/modules/auth';
import { useMainStore } from '../store/modules/main';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import IconEmail from '@/assets/images/icons/email.png'
import IconGmail from '@/assets/images/icons/gmail.png'
import { FormVuetify } from '@/types/common/vuetify';
import { roles } from '@/types/common';
import TextLoaderButton from '@/components/common/LoaderButtons.vue';

@Options({
  name: 'LoginPage',
  components : {
    TextLoaderButton
  }
})
export default class LoginPage extends Vue {
  public loading = false;
  public email = '';
  public password = '';
  public showPassword = false;
  private redirect?: string;
  public isEmail = true;
  public isGmail = false;
  public IconEmail = IconEmail;
  public IconGmail = IconGmail;

  validateEmail = validateEmail;
  requiredField = requiredField;

  public storeAuth = useAuthStore()
  public storeMain = useMainStore()

  created(): void {
    this.$watch('$route', (route: RouteLocationNormalizedLoaded) => {
      const query = route.query;
      if (query) {
        this.redirect = query.redirect as string
      }
    }, { immediate: true })
  }

  mounted() {
    if (this.storeAuth.isLogged) {
      this.$router.push("/dashboard");
    }
  }

  async login() {
    this.loading = true;
    //eslint-disable-next-line
    const form = this.$refs["loginForm"] as FormVuetify;
    const isValid = await form.validate();
    if (isValid.valid) {
      try {
        await this.storeAuth.login({ password: this.password, username: this.email });
        await this.storeAuth.getUser();
        this.$nextTick(() => {
          if (this.storeAuth.user && this.storeAuth.user.role === roles.CUSTOMER) {
            const user = this.storeAuth.user;
            this.$router.push('/booking');
            this.storeMain.setNotification({
              isOpen: true,
              message: `Bienvenido ${user.firstName} ${user.lastName}`,
              color: 'green darken-3'
            })
          } else {
            if(this.redirect){
              this.$router.push(this.redirect)
            }else{
              this.$router.push('/dashboard')
            }
            
          }
        })
      } catch (error) {
        getErrorCatch(error)
      }
    }
    this.loading = false;
  }
}

