import { RouteLocationNormalized } from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import router from "./router";
import { useAuthStore } from "./store/modules/auth";
import { roles } from "./types/common";

NProgress.configure({ showSpinner: true });

const whiteList = [
  "/login",
  "/",
  "/registro",
  "/404",
  "/terminos-condiciones",
  "/politica-privacidad",
  "/booking",
  "/registrov2",
  '/recuperar-cuenta',
  '/contacto',
  '/punto-subida'
];

const driverRoutes = ["/calendario"];

/* eslint-disable */

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    _: RouteLocationNormalized,
    next: any
  ) => {
    NProgress.start();
    const store = useAuthStore();
    if (store.token) {
      const path = to.path;
      if(path == '/404'){
        next();
        return;
      }
      if (store.isLogged && store.user) {
        const user = store.user;
        if (to.path === "/dashboard") {
          NProgress.done();
          next();
          return;
        }
        switch (user.role) {
          case roles.DRIVER:
            if (!driverRoutes.includes(to.path)) {
              next(driverRoutes[0]);
            } else {
              next();
            }
            NProgress.done();
            return;
            break;
          default:
            break;
        }
        next();
      } else {
        try {
          await store.getUser();
          next({ ...to, replace: true });
        } catch (error) {
          store.resetToken();
          next(`/login?redirect=${encodeURI(to.fullPath)}`);
          NProgress.done();
        }
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next();
      } else {
        next(`/login?redirect=${encodeURI(to.fullPath)}`);
        NProgress.done();
      }
    }
  }
);

router.afterEach((to: RouteLocationNormalized) => {
  // Finish progress bar
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done();
});
