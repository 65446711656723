

import { GetTripResponse } from '@/types/response/trip';
import { Options, Vue } from 'vue-class-component';
import LocationPayment from '@/assets/images/icons/location-payment.svg'
import Line from '@/assets/images/icons/linea.svg'
import { convertTo12HourFormat, getDate } from '@/filters/formatDate';
import { STATUS_ACTIVE_TRIP } from '@/types/response/planning';
import { formatCurrency } from '@/utils';

@Options({
    name: 'ModalTripDetail',
    props: {
        tripSelected: {
            type: Object,
            default: () => ({}),
            required: false
        },
        isVisible: {
            type: Boolean,
            default: false,
            required: true
        }
    }
})
export default class ModalTripDetail extends Vue {
    public tripSelected !: GetTripResponse;
    public isVisible!: boolean;
    public LocationPayment = LocationPayment;
    public Line = Line;
    public convertTo12HourFormat = convertTo12HourFormat;
    public getDate = getDate;
    public formatCurrency = formatCurrency;

    closeDialog() {
        this.$emit('closeModal')
    }
    getStatus(entryStatus: string) {
        console.log(entryStatus)
        const status: Record<string, string> = {
            'ON_HOLD': 'En espera',
            'IN_PROGRESS': 'En progreso',
            'FINALIZED': 'Finalizado',
            'REMOVED' : "Viaje eliminado por admin."
        }
        return status[entryStatus] || ''
    }
    getColorIcon(status: STATUS_ACTIVE_TRIP) {
        let color = 'black'
        switch (status) {
            case STATUS_ACTIVE_TRIP.ON_HOLD:
                color = 'grey';
                break;
            case STATUS_ACTIVE_TRIP.IN_PROGRESS:
                color = 'green';
                break;
            case STATUS_ACTIVE_TRIP.FINALIZED:
                color = 'primary';
                break;
            case STATUS_ACTIVE_TRIP.REMOVED:
                color = 'red';
                break;
        }
        return `${color} darken-2`
    }
}
