
import { Options, Vue } from 'vue-class-component';
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { useUserStore } from '../../store/modules/user';
import { getErrorCatch } from '../../utils';
import { formatDateCompleted } from '@/filters/formatDate'
import { useMainStore } from '../../store/modules/main';
import { UrlConstants } from '@/constants/url.constants'
import visaImagen from '@/assets/images/visa_logo.png';
import { IResponseGetGuest } from '@/types/response/guest';
import UpdateCustomer from './UpdateCustomer.vue';
import { UserApi } from '@/api/users';

@Options({
  name: 'TableGuest',
  components: {
    UpdateCustomer
  }
})
export default class TableGuest extends Vue {
  UrlConstants = UrlConstants
  private userStore = useUserStore();
  private mainStore = useMainStore();
  private userApi = new UserApi();
  public visaImagen = visaImagen;
  public formatDateCompleted = formatDateCompleted;
  public loadingTable = false;
  public fullName = '';
  public rut = '';
  public customerSelected: IResponseGetGuest | null = null;
  public openEditCustomer = false;
  public serverOptions: ServerOptions = {
    page: 1,
    rowsPerPage: 5
  }

  public headers: Header[] = [
    { text: "Fec. de registro", value: "dateCreated" },
    { text: "Nombre completo", value: "fullName" },
    { text: "Celular", value: "phone" },
    { text: "Email", value: "email" },
    { text: "Dirección", value: "dataCustomer" },
    { text: "Flow", value: "cardResult" },
    { text: "Estado", value: "status" },
    { text: "", value: "actions" },
  ];


  get total() {
    return this.userStore.totalGuest
  }

  get items() {
    return this.userStore.guest
  }

  get fullNameQuery() {
    return this.userStore.paginationGuest.fullName
  }

  get rutQuery() {
    return this.userStore.paginationGuest.rut;
  }

  created() {
    this.$watch('serverOptions', ({ page, rowsPerPage }: ServerOptions) => {
      this.userStore.setPage(page);
      this.userStore.setLimit(rowsPerPage);
      this.getUsers();
    })
  }

  mounted(): void {
    if (this.$route.query.rut) {
      const searchRut = this.$route.query.rut as string;
      this.userStore.setRUT(searchRut);
      this.rut = '';
    }
    this.getUsers()
  }


  async getUsers() {
    this.loadingTable = true
    try {
      await this.userStore.getUsers();
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingTable = false;
  }

  async createSign(user: string) {
    this.loadingTable = true;
    try {
      await this.userApi.refreshSign(user);
      await this.getUsers();
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingTable = false;
  }

  async signUser(user: string, value: boolean) {
    this.loadingTable = true;
    try {
      await this.userApi.signUser(user, value);
      await this.getUsers();
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingTable = false;
  }

  removeSign(user: string) {
    this.signUser(user, false)
  }

  setSign(user: string) {
    this.signUser(user, true)
  }

  async resendEmailSign(user: string) {
    this.loadingTable = true;
    try {
      await this.userApi.resendSign(user);
      await this.getUsers();
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingTable = false;

  }

  copy(link: string) {
    try {
      navigator.clipboard.writeText(link);
      this.mainStore.setNotification({ isOpen: true, message: 'Enlace copiado', color: 'green', timeout: 200000 })
    } catch (error) {
      getErrorCatch(error)
    }
  }
  setFullName() {
    if (this.fullName == '') return;
    this.userStore.setFullname(this.fullName);
    this.fullName = '';
    this.getUsers();
  }
  removeFullName() {
    this.userStore.setFullname('');
    this.getUsers();
  }
  removeRut() {
    this.userStore.setRUT('');
    this.getUsers();
  }
  setRUT() {
    if (this.rut === '') return;
    this.userStore.setRUT(this.rut);
    this.rut = '';
    this.getUsers();
  }
  getLetters(firstName?: string, lastName?: string) {
    return `${firstName?.slice(0, 1).toLocaleUpperCase() || ''}${lastName?.slice(0, 1).toLocaleUpperCase() || ''}`
  }
  getURLFlowCustomer(customerID: string) {
    return `${UrlConstants.URL_FLOW_WEB}app/web/subscription/sCustomerSee.php?customerId=${customerID}`
  }
  setUpdateCustomer(customer: string) {
    this.customerSelected = this.items.find(({ _id }) => _id == customer) || null;
    this.openEditCustomer = true;
  }
  closeModalUpdateCustomer() {
    this.openEditCustomer = false;
    this.customerSelected = null;
  }
  async activeCustomer(id: string, active = true) {
    this.loadingTable = true;
    try {
      if (active) {
        await this.userApi.activeUser(id)
      } else {
        await this.userApi.desactiveUser(id)
      }
      await this.getUsers()
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingTable = false;
  }
}
