import { AxiosInstance } from "axios";
import API from "@/utils/request";
import { ROUTES_GLOBAL } from "@/api/paths";
import { USER_ENDPOINTS } from "./endpoints";
import {
  CreateDriverRequest,
  IRequestCreateGuest,
  IRequestGetGuest,
  UpdateCustomerRequest,
} from "@/types/request/guest";
import {
  GetUsersAutocomplete,
  GetUsersRut,
  IAddCardCustomerResponse,
  IResponsePaginationGetGuest,
} from "../../types/response/guest";
import { ResponseCommonUsers } from "@/types/common/api";

export class UserApi {
  private request = new API(ROUTES_GLOBAL.USER);
  public api: AxiosInstance = this.request.getInstance();

  async createCustomer(dataCreated: IRequestCreateGuest) {
    const { data } = await this.api.post<string>(
      USER_ENDPOINTS.CREATE_CUSTOMER,
      dataCreated
    );
    return data;
  }

  async createGuest(dataCreated: IRequestCreateGuest) {
    const { data } = await this.api.post<string>(
      USER_ENDPOINTS.CREATE_GUEST,
      dataCreated
    );
    return data;
  }

  async getUsers({ limit, page, fullName, rut }: IRequestGetGuest) {
    let URL = `${USER_ENDPOINTS.GET_GUEST}?page=${page}&limit=${limit}`;
    if (fullName) {
      URL += `&fullName=${fullName}`;
    }
    if (rut) {
      URL += `&rut=${rut}`;
    }
    const { data } = await this.api.get<IResponsePaginationGetGuest>(URL);
    return data;
  }

  async getDrivers({ limit, page, fullName, rut }: IRequestGetGuest) {
    let URL = `${USER_ENDPOINTS.GET_DRIVERS}?page=${page}&limit=${limit}`;
    if (fullName) {
      URL += `&fullName=${fullName}`;
    }
    if (rut) {
      URL += `&rut=${rut}`;
    }
    const { data } = await this.api.get<IResponsePaginationGetGuest>(URL);
    return data;
  }
  async createDriver(data: CreateDriverRequest) {
    await this.api.post(USER_ENDPOINTS.CREATE_DRIVER, data);
  }
  async getDriversByName(fullName: string) {
    const { data } = await this.api.get<GetUsersAutocomplete[]>(
      USER_ENDPOINTS.GET_DRIVER_BYNAME + fullName
    );
    return data;
  }
  async getCustomerByName(fullName: string) {
    const { data } = await this.api.get<GetUsersAutocomplete[]>(
      USER_ENDPOINTS.GET_CUSTOMERS_BYNAME + fullName
    );
    return data;
  }
  async getAllDrivers() {
    const { data } = await this.api.get<ResponseCommonUsers[]>(
      USER_ENDPOINTS.GET_ALL_DRIVERS
    );
    return data;
  }

  async getCustomerByRUT(rut: string) {
    const { data } = await this.api.get<GetUsersRut>(
      USER_ENDPOINTS.GET_CUSTOMER_RUT + rut
    );
    return data;
  }

  async addCard(pathReturn: string) {
    const { data } = await this.api.post<IAddCardCustomerResponse>(
      USER_ENDPOINTS.ADD_CARD_FLOW,
      { pathReturn }
    );
    return data;
  }

  async updateCustomer(data: UpdateCustomerRequest, id: string) {
    await this.api.patch(USER_ENDPOINTS.UPDATE_CUSTOMER + `/${id}`, data);
  }

  async refreshSign(user: string) {
    await this.api.post(USER_ENDPOINTS.REFRESH_SIGN + user);
  }

  async resendSign(user: string) {
    await this.api.post(USER_ENDPOINTS.RESEND_SIGN + user);
  }

  async signUser(user: string, isSign: boolean) {
    await this.api.patch(USER_ENDPOINTS.SIGN_USER + user, { isSign });
  }

  async activeUser(user : string){
    await this.api.patch(USER_ENDPOINTS.ACTIVE_USER + user);
  }
  
  async desactiveUser(user : string){
    await this.api.patch(USER_ENDPOINTS.DESACTIVE_USER + user);
  }
}
