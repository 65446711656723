import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-grey"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "text-grey"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EasyDataTable = _resolveComponent("EasyDataTable")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mt-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_EasyDataTable, {
            loading: _ctx.loadingTable,
            headers: _ctx.headers,
            items: _ctx.items,
            "server-items-length": _ctx.total,
            "server-options": _ctx.serverOptions,
            "onUpdate:serverOptions": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.serverOptions) = $event)),
            "buttons-pagination": ""
          }, {
            "item-dateCreated": _withCtx(({ dateCreated }) => [
              _createTextVNode(_toDisplayString(_ctx.formatDateCompleted(dateCreated)), 1)
            ]),
            "item-condominium": _withCtx(({ condominium }) => [
              (!condominium)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Sin datos"))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(condominium), 1))
            ]),
            "item-commune": _withCtx(({ commune }) => [
              (!commune)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Sin datos"))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(commune), 1))
            ]),
            "item-firstName": _withCtx(({ firstName, lastName }) => [
              _createElementVNode("p", null, _toDisplayString(firstName) + " " + _toDisplayString(lastName), 1)
            ]),
            _: 1
          }, 8, ["loading", "headers", "items", "server-items-length", "server-options"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}