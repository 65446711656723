import { defineStore } from "pinia";
import { IResponseGetGuest } from "@/types/response/guest";
import { UserApi } from "../../api/users";
import { IRequestGetGuest } from "@/types/request/guest";

interface IAuthStore {
  guest: IResponseGetGuest[];
  totalGuest: number;
  paginationGuest: IRequestGetGuest;
}

const userApi = new UserApi();

export const useUserStore = defineStore("user", {
  // other options...
  state: (): IAuthStore => ({
    guest: [],
    totalGuest: 0,
    paginationGuest: {
      limit: 5,
      page: 1,
    },
  }),
  actions: {
    async getUsers() {
      const { items, total } = await userApi.getUsers({
        limit: this.paginationGuest.limit,
        page: this.paginationGuest.page,
        fullName : this.paginationGuest.fullName,
        rut : this.paginationGuest.rut
      });
      this.guest = items;
      this.totalGuest = total;
    },
    setPage(page: number) {
      this.paginationGuest.page = page;
    },
    setLimit(limit: number) {
      this.paginationGuest.limit = limit;
    },
    setFullname(name:string){
      this.paginationGuest.fullName = name;
    },
    setRUT(rut:string){
      this.paginationGuest.rut = rut;
    }
  },
});
