import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import {
  CreateTripRequest,
  GetTripsPagination,
  ResendEmail,
  TypeCancelTrip,
} from "@/types/request/trip";
import { TRIP_ENDPOINTS } from "./endpoints";
import {
  CreateTripFreeResponse,
  CreateTripResponse,
  GetStatusTripResponse,
  GetTripPaginationResponse,
} from "@/types/response/trip";

export class TripApi {
  private request = new API(ROUTES_GLOBAL.TRIPS);
  private api: AxiosInstance = this.request.getInstance();

  async createTrip(createTripRequest: CreateTripRequest, isAuth = false) {
    const { data } = await this.api.post<
      CreateTripResponse | CreateTripFreeResponse
    >(
      isAuth ? TRIP_ENDPOINTS.CREATE_TRIP_AUTH : TRIP_ENDPOINTS.CREATE_TRIP,
      createTripRequest
    );
    return data;
  }

  async createTripAdmin(createTripRequest: CreateTripRequest) {
    const { data } = await this.api.post<
      CreateTripResponse | CreateTripFreeResponse
    >(TRIP_ENDPOINTS.CREATE_TRIP_ADMIN, createTripRequest);
    return data;
  }

  async getTrip(code: string) {
    const { data } = await this.api.get<GetStatusTripResponse>(
      TRIP_ENDPOINTS.GET_TRIP + code
    );
    return data;
  }

  async getTrips({ limit, page, code, customer,dateFinish,dateInit,planning,warningTrip}: GetTripsPagination) {
    let URL = `${TRIP_ENDPOINTS.GET_TRIPS}?page=${page}&limit=${limit}`;
    if (code) {
      URL += "&code=" + code;
    }
    if (customer) {
      URL += "&customer=" + customer;
    }
    if (planning) {
      URL += "&planningTrip=" + planning;
    }
    if (dateFinish && dateInit) {
      URL += "&dateFinish=" + dateFinish + "&dateInit=" + dateInit;
    }
    if(warningTrip){
      URL+="&warningTrip=true";
    }
    const { data } = await this.api.get<GetTripPaginationResponse>(URL);
    return data;
  }
  async resendEmail({ typeResend, email, code }: ResendEmail) {
    await this.api.post(TRIP_ENDPOINTS.RESEND_EMAIL + code, {
      typeResend,
      email,
    });
  }

  async cancelTrip(id: string, typeCancel?: TypeCancelTrip) {
    let URL = TRIP_ENDPOINTS.CANCEL_TRIP + id;
    if(typeCancel){
      URL+="?cancelTypeTrip="+typeCancel;
    }
    const { data } = await this.api.delete<string>(URL);
    return data;
  }
}
