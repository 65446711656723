import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainDriver = _resolveComponent("MainDriver")!
  const _component_MainCustomer = _resolveComponent("MainCustomer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d flex-column w-100", _ctx.user?.role === 'CUSTOMER' ? ' py-0 ' : 'py-2'])
  }, [
    (_ctx.user?.role === 'DRIVER')
      ? (_openBlock(), _createBlock(_component_MainDriver, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.user?.role === 'CUSTOMER')
      ? (_openBlock(), _createBlock(_component_MainCustomer, { key: 1 }))
      : _createCommentVNode("", true)
  ], 2))
}