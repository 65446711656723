
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { GetCalendarResponse, GetCalendarSeating, PlanningTripsResponse, PlanningTripsStationsResponse } from '@/types/response/planning';
import { usePlanningStore } from '@/store/modules/planning';
import { convertTo12HourFormat, formatDateStart, formatDateStartJSDate, getDate } from '@/filters/formatDate';
import { SelectData } from '@/types/common/vuetify'
import AddTrip from './AddTrip.vue';
import { getErrorCatch, translate } from '@/utils';
import { PlanningTripAPI } from '@/api/planning/';

interface Markers {
    date: Date | string;
    type?: 'dot' | 'line';
    tooltip?: { text: string; color?: string; }[];
    color?: string;
}

@Options({
    name: 'CalendarPlanning',
    components: {
        ModalCommon,
        AddTrip
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        planning: {
            type: Object,
            default: null
        },
        stations: {
            type: Array,
            default: () => ([])
        },
        calendar: {
            type: Array,
            default: () => ([])
        }
    }
})
export default class CalendarPlanning extends Vue {
    public convertTo12HourFormat = convertTo12HourFormat;
    public getDate = getDate;
    private planningStore = usePlanningStore();
    private apiPlaning = new PlanningTripAPI()
    public planning !: PlanningTripsResponse;
    public stations!: PlanningTripsStationsResponse[];
    public visible!: boolean;
    public date: Date | null = null;
    public title = '';
    public markers: Markers[] = [];
    public selectedDay: GetCalendarResponse | null = null;
    public calendar!: GetCalendarResponse[];
    public stationsNew: SelectData[] = [];
    public seatingsAvailable: SelectData[] = [];
    public isVisibleAddTrip = false;

    public isVisibleDeleteModal = false;
    public deleteLoading = false;
    public seatingSelect: GetCalendarSeating | null = null;
    public isVisibleDeleteActiveTrip = false;
    public isLoadingDeleteActiveTrip = false;
    public loadingPDF = false


    get isMobile() {
        const rect = document.body.getBoundingClientRect()
        return rect.width - 1 < 572
    }

    public translate = translate;

    created() {
        this.$watch('visible', (data: boolean) => {
            if (data) { this.loadingCalendar() }
        }, { immediate: true })
        this.$watch('selectedDay', (data: GetCalendarResponse | null) => {
            if (data == null || this.selectedDay == null) return;
            this.selectedDay.seatings = [...this.selectedDay.seatings].map((seating) => {
                const station = this.stations.find(({ _id }) => _id === seating.station)
                if (!station) return seating;
                return {
                    ...seating,
                    origin: {
                        name: station.routeStation.originStation,
                        hour: station.hourInit
                    },
                    finish: {
                        name: station.routeStation.finalStation,
                        hour: station.hourFinish
                    }
                }
            })
        })
    }
    loadingCalendar() {
        if (!this.planning) return;
        this.title = this.planning.name
        this.date = new Date() //this.planning.days[0] as Date;
        this.markers = [];
        this.calendar.forEach(data => {
            this.markers.push({
                date: data.dateExecuting,
                color: data.status === 'REMOVED' ? '#D32F2F' : '#03cbf9',
                type: 'line',
                tooltip: [{ text: `Asientos: ${data.seatings.filter(({ status }) => status !== 'POSTPONED').length}`, color: 'green' }],
            })
        });
        this.selectedDay = this.calendar[0];
        this.loadStations();
        this.loadSeating()
    }
    loadSeating() {
        if (!this.selectedDay) return;
        const seating = Array.from({ length: this.planning.vehicle.seatings }, (_, index) => index + 1);
        this.seatingsAvailable = seating.filter((seatingEntry) => {
            const seating = this.selectedDay?.seatings.find(({ seating, status }) => parseInt(seating) == seatingEntry && status !== 'POSTPONED')
            return !seating;
        }).map((value) => ({
            title: value.toString(),
            value: value.toString()
        }))
    }
    loadStations() {
        this.stationsNew = this.stations.map(({ _id, routeStation }) => ({
            title: `${routeStation.keyOrder}. ${routeStation.originStation} - ${routeStation.finalStation}`,
            value: _id
        }))
    }
    setDate(modelData: Date) {
        const data = formatDateStartJSDate(modelData);
        const dataFull = data.toFormat('yyyy-MM-dd');
        this.selectedDay = this.calendar.find(element => {
            const dataFullCalendar = formatDateStart(element.dateExecuting as string).toFormat('yyyy-MM-dd')
            return dataFullCalendar === dataFull;
        }) || null;
        this.loadSeating();
    }
    closeModal() {
        this.planningStore.resetCalendar()
        this.$emit('closeModal')
    }
    sorted(a: GetCalendarSeating, b: GetCalendarSeating) {
        const seating1 = parseInt(a.seating);
        const seating2 = parseInt(b.seating);
        return seating1 - seating2;
    }
    reloadCalendar(id: string) {
        this.$nextTick(() => {
            this.selectedDay = this.calendar.find(element => element._id == id) || null;
            this.seatingsAvailable = this.seatingsAvailable.filter(({ value }) => {
                return !this.selectedDay?.seatings.map(({ seating }) => seating).includes(value);
            })
        })
    }

    visibleDeleteSeating(seating: GetCalendarSeating) {
        this.seatingSelect = seating;
        this.isVisibleDeleteModal = true;
    }

    closeModalDelete() {
        this.seatingSelect = null;
        this.isVisibleDeleteModal = false
    }

    async deleteSeating() {
        if (!this.seatingSelect) return;
        this.deleteLoading = true
        try {
            await this.apiPlaning.suspendSeating(this.seatingSelect._id);
            await this.planningStore.getCalendar(this.planning._id);
            this.loadSeating();
            if (this.selectedDay) {
                this.reloadCalendar(this.selectedDay._id)
                this.seatingsAvailable = this.seatingsAvailable.filter(({ value }) => {
                    return !this.selectedDay?.seatings.map(({ seating }) => seating).includes(value);
                })
            }

            this.loadingCalendar();
            this.closeModalDelete()
        } catch (error) {
            getErrorCatch(error)
        }
        this.deleteLoading = false;
    }

    async deleteActiveTrip() {
        if (!this.selectedDay) return
        this.isLoadingDeleteActiveTrip = true;
        try {
            await this.apiPlaning.removeActiveTrip(this.selectedDay._id)
            this.closeModalDeleteActiveTrip()
            this.$emit('reloadCalendar', this.planning._id)
        } catch (error) {
            getErrorCatch(error)
        }
        this.isLoadingDeleteActiveTrip = false;
    }
    closeModalDeleteActiveTrip() {
        this.isVisibleDeleteActiveTrip = false;
    }
    async download() {
        if (!this.selectedDay) return
        this.loadingPDF = true
        try {
            const data = await this.apiPlaning.getPDF(this.selectedDay._id)
            const url = URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = url;
            a.download = "Calendario_" + this.planning.name + "_" + this.selectedDay.dateExecuting.toLocaleString().split("T")[0] + ".pdf";
            document.body.appendChild(a);
            a.target = "_blank";
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingPDF = false
    }
}
