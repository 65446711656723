
import { Vue, Options } from 'vue-class-component';
import Points from '@/assets/images/home/points.svg'
import Arrows from '@/assets/images/home/arrows.svg'
import Line from '@/assets/images/lines.svg'
import BannerMobile from '@/assets/images/banners/banner_mobile.png'
import Banner from '@/assets/banner.png'
import { customScrollTo, getErrorCatch } from '@/utils';
import FormSearch from '@/components/Booking/FormSearch.vue';
import '@/scss/booking.scss'
import { useRoutesStore } from '@/store/modules/route';

@Options({
  name: 'BannerMain',
  components: {
    FormSearch
  }
})
export default class BannerMain extends Vue {
  private routeStore = useRoutesStore();
  public points = Points;
  public arrows = Arrows;
  public line = Line;
  public banner = BannerMobile
  public Banner = Banner;

  toScroll(e: Event, data: string) {
    e.preventDefault()
    const path = this.$route.path;
    if (!data.includes("#")) {
      this.$router.push(data);
      return;
    }
    if (path === '/') {
      const topOffset = 80
      const sectionId = data.split("#")[1]
      customScrollTo(sectionId, topOffset)
    } else {
      this.$router.push(data)
    }
  }
  async mounted() {

    try {
      await this.routeStore.getStations();
    } catch (error) {
      getErrorCatch(error)
    }
  }
}
