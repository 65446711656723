
import { Vue, Options } from 'vue-class-component';
import HeaderCommonBooking from './HeaderCommonBooking.vue';
import { usePlanningStore } from '@/store/modules/planning';
import { useRoutesStore } from '@/store/modules/route';
import { convertTo12HourFormat, getDate } from '@/filters/formatDate';
import { formatCurrency, getErrorCatch, requiredField } from '@/utils';
import webPayImagen from '@/assets/images/logo-webpay.png';
import onePayImagen from '@/assets/images/logo-onepay.png';
import visaImagen from '@/assets/images/visa_logo.png';
import { FormVuetify } from '@/types/common/vuetify';
import { TripApi } from '@/api/trip'
import { CreateTripRequest } from '@/types/request/trip';
import { GetCouponNameResponse } from '@/types/response/coupon';
import Line from '@/assets/images/icons/linea.svg'
import LocationPayment from '@/assets/images/icons/location-payment.svg'
import { CouponApi } from '@/api/coupons';
import { useMainStore } from '@/store/modules/main';
import { useAuthStore } from '@/store/modules/auth';
import { CreateTripFreeResponse, CreateTripResponse } from '@/types/response/trip';
import ModalLogin from '../common/ModalLogin.vue';
import { UserApi } from '@/api/users'
import TextLoaderButton from '../common/LoaderButtons.vue';

@Options({
    name: 'Payment',
    components: {
        HeaderCommonBooking,
        ModalLogin,
        TextLoaderButton
    }
})
export default class Payment extends Vue {
    public routeStore = useRoutesStore();
    private authStore = useAuthStore()
    private userApi = new UserApi();
    private couponApi = new CouponApi()
    public LocationPayment = LocationPayment;
    public Line = Line;
    public planningStore = usePlanningStore();
    public mainStore = useMainStore();
    private tripApi = new TripApi();
    public convertTo12HourFormat = convertTo12HourFormat;
    public formatCurrency = formatCurrency;
    public webPayImagen = webPayImagen;
    public onePayImagen = onePayImagen;
    public visaImagen = visaImagen;
    public requiredField = requiredField;
    public getDate = getDate;
    public select = 1
    public policy = false;
    public loadingTrip = false;
    public isCoupon = false;
    public couponName = '';
    public loadingCoupon = false;
    public couponResponse: GetCouponNameResponse | null = null;
    public discount = 0;
    public isVisibleLogin = false;
    public storeAuth = useAuthStore();
    public loadingAddCard = false;

    get searchTripBooking() {
        return this.planningStore.searchSelected;
    }

    get passengers() {
        return this.planningStore.passengers;
    }

    get passengersFinal() {
        return this.planningStore.passengersFinal;
    }

    get stations() {
        return this.routeStore.stationsList
    }

    get tripSelected() {
        return this.planningStore.tripSelected;
    }

    get tripFinalSelected() {
        return this.planningStore.tripFinalSelected;
    }

    get total() {
        let price = this.tripSelected?.price || 0;
        if (this.searchTripBooking?.typeTrip === 'round') {
            price += this.tripFinalSelected?.price || 0
        }
        return price * (1 + this.passengers.length)
    }

    get owner() {
        return this.planningStore.owner
    }

    get ownerFinal() {
        return this.planningStore.ownerFinal
    }

    getStationSelected(id?: string) {
        return this.stations.find(({ _id }) => _id === id)?.name || ''
    }

    formatNumber(num: number) {
        if (num < 10) {
            return "0" + num;
        }
        return num + '';
    }

    back() {
        this.planningStore.setStep('PASSENGERS');
    }
    async nextTo() {
        const form = this.$refs['formPayment'] as FormVuetify;
        if (!form) return;
        const valid = await form.validate();
        if (valid.valid) {
            if (this.tripSelected && this.owner && this.searchTripBooking) {

                const data: CreateTripRequest = {
                    activeTrip: this.tripSelected.activeTrip,
                    hourArrival: this.tripSelected.hourFinish,
                    hourDeparture: this.tripSelected.hourInit,
                    seating: this.owner.seating,
                    totalPrice: this.total,
                    stationOrigin: this.getStationSelected(this.searchTripBooking.origin),
                    stationDestination: this.getStationSelected(this.searchTripBooking.destination),
                    passengers: this.passengers,
                    planningTrip: this.tripSelected.planingTrip,
                    guest: {
                        ...this.owner
                    },
                    station: this.tripSelected._id,
                    coupon: this.couponResponse ? this.couponName : undefined,
                    typePayment: this.select === 1 ? 'webpay' : 'oneclick',
                    typeTrip: this.searchTripBooking.typeTrip,
                }
                if (data.typeTrip === 'round') {
                    if (this.tripFinalSelected && this.ownerFinal && this.passengersFinal) {
                        data.returnTrip = {
                            activeTrip: this.tripFinalSelected.activeTrip,
                            hourArrival: this.tripFinalSelected.hourFinish,
                            hourDeparture: this.tripFinalSelected.hourInit,
                            planningTrip: this.tripFinalSelected.planingTrip,
                            seatingOwner: this.ownerFinal.seating,
                            station : this.tripFinalSelected._id,
                            seatings : this.passengersFinal.map(({seating})=>seating),
                            totalPrice: this.tripFinalSelected.price * this.passengersFinal.length
                        }
                    } else {
                        this.mainStore.setNotification({
                            isOpen: true,
                            message: 'Ocurrio un problema con la información de retorno',
                            color: 'red-darken-3',
                            timeout: 3000
                        })
                        console.error("dataReturn not exist")
                        return;
                    }
                }
                this.loadingTrip = true;
                try {
                    const responseTrip = await this.tripApi.createTrip(data, this.authStore.isLogged);
                    if ((responseTrip as CreateTripFreeResponse).code) {
                        window.location.href = '/booking?confirm=' + (responseTrip as CreateTripFreeResponse).code
                    } else {
                        const { token, url } = responseTrip as CreateTripResponse
                        window.location.href = `${url}?token=${token}`;
                    }

                } catch (error) {
                    getErrorCatch(error)
                }
                this.loadingTrip = false;
            }
        }
    }
    async searchCoupon() {
        if (this.couponName === '') return;
        this.loadingCoupon = true;
        try {
            const coupon = await this.couponApi.validateCoupon(this.couponName, this.total);
            this.discount = coupon.type === 'PERCENTAJE' || coupon.percentaje ? this.calculatePercenjate(coupon) : coupon.amount || 0;
            this.couponResponse = coupon
            this.mainStore.setNotification({
                isOpen: true,
                message: 'Cupón agregado correctamente',
                color: 'green darken-2',
                buttonClose : true
            })
            localStorage.setItem('coupon', JSON.stringify(coupon))
        } catch (error) {
            getErrorCatch(error)
            this.couponResponse = null;
            this.discount = 0
        }
        this.loadingCoupon = false;
    }
    calculatePercenjate(coupon?: GetCouponNameResponse) {
        if (coupon) {
            if ((coupon.type === 'PERCENTAJE' || coupon.percentaje) && coupon.percentaje) {
                const discount = this.total * (coupon.percentaje / 100)
                return isNaN(discount) ? 0 : discount
            }
        }
        return 0
    }
    async addCardFlow() {
        this.loadingAddCard = true;
        try {
            const { token, url } = await this.userApi.addCard('booking?addCard=')
            window.location.href = `${url}?token=${token}`;
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingAddCard = false;
    }
}
