
import HeaderCommonBooking from '@/components/Booking/HeaderCommonBooking.vue';
import { Options, Vue } from 'vue-class-component';
import Passenger from '@/assets/images/passenger.png'
import { useAuthStore } from '@/store/modules/auth';
import { formatCurrency } from '@/utils';
import { useMainStore } from '@/store/modules/main';

@Options({
    components: {
        HeaderCommonBooking
    },
})
export default class Profile extends Vue {
    public Passenger = Passenger;
    private authStore = useAuthStore();
    private mainStore = useMainStore();
    public formatCurrency = formatCurrency;
    public tab = 1;

    get user() {
        return this.authStore.user
    }

    get coupons() {
        if (!this.user) return []
        return this.user.coupons || []
    }

    back() {
        this.$router.back()
    }

    copy(name: string) {
        navigator.clipboard.writeText(name);
        this.mainStore.setNotification({
            isOpen: true,
            message: 'Cupón copiado correctamente',
            color: 'blue darken-2'
        })
    }
}
