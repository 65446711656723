/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ErrorPage from "../views/ErrorPage.vue";
import Register from "../views/Register.vue";
import Dashboard from "../views/Dashboard.vue";
import Usuarios from "../views/Usuarios.vue";
import Drivers from "../views/Drivers.vue";
import Routes from "../views/Routes.vue";
import Login from "../views/Login.vue";
import PrivacyTerms from "../views/PrivacyTerms.vue";
import PlanningTrip from "../views/PlanningTrip.vue";
import Planning from "../views/Planning.vue";
import Vehicles from "../views/Vehicles.vue";
import Booking from "../views/Booking.vue";
import Trips from "../views/Trips.vue";
import DriverCalendar from "../views/DriverCalendar.vue";
import TermsConditions from "../views/TermsConditions.vue";
import LayoutFront from "../layout/LayoutPublic.vue";
import LayoutAuth from "../layout/LayoutAuth.vue";
import Registro from '../views/Registro.vue'
import ResetPassowrd from '../views/ResetPassword.vue'
import Coupons from '../views/Coupons.vue'
import Logger from '../views/Logger.vue'
import Contact from '../views/Contact.vue'
import PuntoSubida from '../views/PuntoSubida.vue'
import Profile from '../views/Profile.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
  {
    path: "/",
    component: LayoutFront,
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          title: "Tacerca",
        },
        component: HomeView,
      },
      {
        path: "/registro",
        redirect : '/registrov2',
        name: "register",
      },
      {
        path: '/punto-subida',
        name : 'puntoSubida',
        meta : {
          title : 'Punto subida'
        },
        component : PuntoSubida
      },
      {
        path: '/contacto',
        name : 'contact',
        meta : {
          title : 'Contacto - Tacerca'
        },
        component : Contact
      },
      {
        component: Registro,
        path: '/registrov2',
        name: 'registro',
        meta : {
          title : 'Registro v2'
        }
      },
      {
        component : ResetPassowrd,
        path: '/recuperar-cuenta',
        name : 'resetPasword',
        meta : {
          title : 'Recuperar contraseña'
        }
      },
      {
        path: "/404",
        component: ErrorPage,
      },
      {
        path: "/login",
        name: "login",
        meta: {
          title: "login",
        },
        component: Login,
      },
      {
        path: "/politica-privacidad",
        name: "policyPrivate",
        meta: {
          title: "Política de privacidad",
        },
        component: PrivacyTerms,
      },
      {
        path: "/terminos-condiciones",
        name: "TermsConditions",
        meta: {
          title: "Términos y condiciones",
        },
        component: TermsConditions,
      },
      {
        path: '/booking',
        name: 'Booking',
        component: Booking,
        meta: {
          title : 'Buscar viaje'
        }
      }
    ],
  },
  {
    path: "/",
    component: LayoutAuth,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "Dashboard",
        },
        component: Dashboard,
      },
      {
        path: '/perfil',
        name : 'Profile',
        meta: {
          title: "Perfil",
        },
        component : Profile
      },
      {
        path: "/usuarios",
        name: "users",
        meta: {
          title: "Users",
        },
        component: Usuarios,
      },
      {
        path: "/conductores",
        name: "drivers",
        meta: {
          title: "Conductores",
        },
        component: Drivers,
      },
      {
        path: "/rutas",
        name: "rutas",
        meta: {
          title: "Rutas",
        },
        component: Routes,
      },
      {
        path: "/planificacion",
        name: "PlanningTrip",
        meta: {
          title: "Planificación",
        },
        component: PlanningTrip,
      },
      {
        path: "/planificaciones",
        name: "Planning",
        meta: {
          title: "Planificaciones",
        },
        component : Planning
      },
      {
        path: "/vehiculos",
        component: Vehicles,
        meta: {
          tile: "Vehículos",
        },
        name: "Vehicles",
      },
      {
        path: "/trips",
        component: Trips,
        meta: {
          tile: "Listar viajes",
        },
        name: "FormTrip",
      },
      {
        path : '/calendario',
        component : DriverCalendar,
        name : 'Calendario'
      },
      {
        path : '/cupones',
        component : Coupons,
        name : 'Coupons'
      },
      {
        path :'/loggers',
        component : Logger,
        name : 'Loggers'
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export function resetRouter() {
  const newRouter = router ;
  (router as any).matcher = (newRouter as any).matcher;
}

export default router;
