import { IPagination } from "../common/api";

export enum TypeCoupon {
  PERCENTAJE = "PERCENTAJE",
  MONEY = "MONEY",
  CUSTOMER = "CUSTOMER",
}

export type TTypeCoupon = 'CUSTOMER' | 'MONEY' | 'PERCENTAJE'

export enum SubTypeCupon{
  PERCENTAJE = "PERCENTAJE",
  MONEY = "MONEY",
}

export enum StatusCoupon {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  OFF = "OFF",
  EXPIRED = "EXPIRED",
}

export interface CreateCouponRequest {  
  name: string;
  type: TTypeCoupon;
  isInfinite: boolean;
  limit?: number | string;
  minAmount?: number | string;
  customer?: string;
  amount?:number | string;
  percentaje?:number | string
  subType?: TTypeCoupon
  isWallet?:boolean
}


export interface GetCouponsRequest extends IPagination  {}