export enum ROUTES_GLOBAL {
  MAIN = "contact/",
  USER = "user/",
  AUTH = "auth/",
  ROUTES_STATION = "route/",
  VEHICLES = 'vehicle/',
  PLANNING_TRIP = 'planning-trip/',
  TRIPS = 'trips/',
  COUPONS = 'coupons/'
}
