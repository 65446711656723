
import { Vue, Options } from 'vue-class-component';
import Notification from '../components/common/Notification.vue';
import NavigationDrawer from '../components/NavigationAuth.vue';
import ToolBar from '../components/ToolBar.vue';
import { useAuthStore } from '@/store/modules/auth';

@Options({
  name: "LayoutAuth",
  components: {
    Notification,
    NavigationDrawer,
    ToolBar
  }
})
export default class LayoutAuth extends Vue {
  public authStore = useAuthStore();
  public keysRoutes = ['/dashboard', '/perfil']
  get user() {
    return this.authStore.user;
  }
  get key() {
    return this.$route.path;
  }
}
