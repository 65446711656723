import { iItemMenu } from "@/components/Menus/types";
import { roles } from "@/types/common";
import { IMenu } from "@/types/main";

export const MainMenuMobile: IMenu[] = [
  {
    name: "Elige tu destino",
    href: "/booking",
    type: "text",
  },
  {
    name: "Contáctanos",
    href: "/contacto",
    type: "text",
  },
  {
    name: "Terminos y condiciones",
    href: "/terminos-condiciones",
    type: "text",
  },
  {
    name: "Políticas y privacidad",
    href: "/politica-privacidad",
    type: "text",
  },
  {
    name: "Puntos de subida",
    href: "/punto-subida",
    type: "text",
  },
  {
    name: "Iniciar sesión",
    href: "/login",
    type: "text",
    isAuth: false,
  },
  {
    name: "Mis viajes",
    href: "/dashboard",
    type: "text",
    isAuth: true,
  },
  {
    name: "Mi perfil",
    href: "/perfil",
    type: "text",
    isAuth: true,
  },
];

export const MainMenu: IMenu[] = [
  {
    name: "Inicio",
    href: "/",
    type: "text",
  },
  {
    name: "Puntos de subida",
    href: "/punto-subida",
    type: "text",
    external: true,
  },
  {
    name: "Iniciar sesión",
    href: "/login",
    type: "text",
    external: true,
    hiddenAuth : true
  },
  {
    name: "Mis viajes",
    href: "/dashboard",
    type: "text",
    external: true,
    isAuth : true
  },
  {
    name: "Mi perfil",
    href: "/perfil",
    type: "text",
    isAuth: true,
    external: true,
  },
  {
    name: "Contáctanos",
    href: "/contacto",
    type: "button",
  },
];

export const AuthMenu: iItemMenu[] = [
  {
    key: 0,
    name: "Usuarios",
    items: [
      {
        icon: "mdi-account",
        text: "Clientes",
        link: "/usuarios",
        exact: true,
        permissions: [roles.ADMIN],
      },
      {
        icon: "mdi-account",
        text: "Conductores",
        link: "/conductores",
        exact: true,
        permissions: [roles.ADMIN],
      },
    ],
  },
  {
    key: 1,
    name: "Gestión viajes",
    items: [
      {
        icon: "mdi-map",
        text: "Rutas",
        link: "/rutas",
        permissions: [roles.ADMIN],
      },
      {
        icon: "mdi-car",
        text: "Vehículos",
        link: "/vehiculos",
        permissions: [roles.ADMIN],
      },
      {
        icon: "mdi-car-cog ",
        text: "Crear Planificación",
        link: "/planificacion",
        permissions: [roles.ADMIN],
      },
      {
        icon: "mdi-calendar",
        text: "Planificación",
        link: "/planificaciones",
        permissions: [roles.ADMIN],
      },
      {
        icon: "mdi-calendar",
        text: "Compras",
        link: "/trips",
        permissions: [roles.ADMIN],
      },
      {
        icon : 'mdi-tag',
        text : 'Cupones',
        link : '/cupones',
        permissions : [roles.ADMIN]
      }
    ],
  },
  {
    key: 2,
    name: "Gestión",
    items: [
      {
        icon: "mdi-map",
        text: "Calendario",
        link: "/calendario",
        permissions: [roles.DRIVER],
      },
    ],
  },
];
