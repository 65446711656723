
import { Vue, Options } from 'vue-class-component';
import { useMainStore } from '../store/modules/main';
import ProfileMenu from './ProfileToolBar.vue';
import { useAuthStore } from '@/store/modules/auth';
import IconLarge from '@/assets/images/logo.svg';
import { customScrollTo } from '@/utils';

@Options({
  name: 'ToolBar',
  components: {
    ProfileMenu
  }
})
export default class ToolBar extends Vue {
  icon = IconLarge;
  public authStore = useAuthStore();
  public activeDrawer = false;
  get user() {
    return this.authStore.user;
  }
  public mainStore = useMainStore()

  signOut() {
    this.authStore.logout();
    this.$router.push('/booking')
    location.reload();
  }

  toggleDrawer() {
    this.mainStore.toggleDrawer();
  }
  toScroll(e: Event, data: string, isDrawer = false) {
    e.preventDefault()
    const path = this.$route.path;
    if (isDrawer) {
      this.activeDrawer = false;
    }
    if (!data.includes("#")) {
      this.$router.push(data);
      return;
    }
    if (path === '/') {
      const topOffset = 80
      const sectionId = data.split("#")[1]
      customScrollTo(sectionId, topOffset)
    } else {
      this.$router.push(data)
    }
  }
  isActive(value: string) {
    return value === this.$route.path
  }
}
