

import { useVehicleStore } from '@/store/modules/vehicle';
import { getErrorCatch } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import type { Header, ServerOptions } from 'vue3-easy-data-table';
import { formatDateCompleted } from '@/filters/formatDate'
import { VehicleResponse } from '@/types/response/vehicle';
import { VehicleAPI } from '@/api/vehicles';
import ModalCommon from '../common/ModalCommon.vue';
@Options({
    name: 'TableVehicles',
    components : {
        ModalCommon
    }
})
export default class TableVehicles extends Vue {
    private vehicleStore = useVehicleStore();
    private vehicleAPI = new VehicleAPI()
    public loadingTable = false;
    public formatDateCompleted = formatDateCompleted;
    public isVisibleModalDelete = false;
    public loadingDelete = false;
    public vehicleSelected: VehicleResponse | null = null;

    public serverOptions: ServerOptions = {
        page: 1,
        rowsPerPage: 5
    }

    get total() {
        return this.vehicleStore.totalVehicle
    }

    get items() {
        return this.vehicleStore.vehicles
    }

    public headers: Header[] = [
        { text: "Patente", value: "patent" },
        { text: "Año", value: "year" },
        { text: "Tipo de vehículo", value: "typeVehicle" },
        { text: "Marca", value: "vehicle.brand" },
        { text: "Modelo", value: "vehicle.model" },
        { text: '', value: 'actions' }
    ];

    created() {
        this.$watch('serverOptions', ({ page, rowsPerPage }: ServerOptions) => {
            this.vehicleStore.setPage(page);
            this.vehicleStore.setLimit(rowsPerPage);
            this.getVehicles();
        })
    }

    mounted(): void {
        this.getVehicles()
    }

    async getVehicles() {
        this.loadingTable = true;
        try {
            await this.vehicleStore.getVehicles();
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingTable = false;
    }

    openModalDeleteVehicle(vehicle: VehicleResponse) {
        this.vehicleSelected = vehicle;
        this.isVisibleModalDelete = true;
    }

    async deleteVehicle() {
        if (!this.vehicleSelected) return
        this.loadingDelete = true;
        try {
            await this.vehicleAPI.deleteVehicle(this.vehicleSelected._id)
            await this.getVehicles()
            this.closeModalDelete();
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingDelete = false
    }

    closeModalDelete() {
        this.isVisibleModalDelete = false;
        this.vehicleSelected = null;
    }
}
