
import { Options, Vue } from 'vue-class-component';
import Contact from '@/components/home/Contact.vue';
import { customScrollTo } from '@/utils';
import ImageMain from '@/assets/images/punto-subida/main.jpg'
import ImageMainVitacura from '@/assets/images/punto-subida/main-vitacura.jpg'
import ImageMainFrancisco from '@/assets/images/punto-subida/main-francisco.jpg'
import Points from '@/assets/images/punto-subida/points.png'

@Options({
  components: {
    Contact
  },
})
export default class PuntoSubida extends Vue {
  public timeOut: number | undefined = undefined
  public ImageMain = ImageMain;
  public ImageMainVitacura = ImageMainVitacura;
  public ImageMainFrancisco = ImageMainFrancisco;
  public Points = Points;
  mounted(): void {
    this.timeOut = setTimeout(() => {
      const router = this.$route;
      const hash = router.hash;
      if (hash) {
        customScrollTo(hash.slice(1), 80)
      }
    }, 200);
  }
  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }
}
