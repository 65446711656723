
import TablePlanning from '@/components/planning/TablePlanning.vue';
import { usePlanningStore } from '@/store/modules/planning';
import { getErrorCatch } from '@/utils';
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'PlanningView',
  components: {
    TablePlanning
  },
})
export default class PlanningView extends Vue {
  private planningStore = usePlanningStore();

  unmounted(): void {
    this.planningStore.resetPlannings();
  }

  async mounted() {
    try {
      await this.planningStore.getDrivers();
    } catch (error) {
      getErrorCatch(error)
    }
  }
}
