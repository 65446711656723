import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    class: _normalizeClass(_ctx.classMain),
    height: _ctx.maxHeight,
    fullscreen: _ctx.visibleFullScreen,
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isVisible) = $event)),
    persistent: _ctx.isPersistent,
    "max-width": _ctx.maxWidth
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { style: {"overflow-x":"scroll"} }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-flex align-center" }, {
            default: _withCtx(() => [
              _createElementVNode("h4", {
                class: _normalizeClass(["text-secondary text-helvetica h-bold", _ctx.classHeader])
              }, _toDisplayString(_ctx.title), 3),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                disabled: _ctx.loading,
                variant: "text",
                density: "compact",
                size: "small",
                color: "red",
                icon: "mdi-close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
              }, null, 8, ["disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "px-3 px-md-4" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }),
          (!_ctx.withoutButtons)
            ? (_openBlock(), _createBlock(_component_v_card_actions, {
                key: 0,
                class: "d-flex justify-center mt-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    variant: "outlined",
                    color: "medium-emphasis",
                    width: "120",
                    class: "mr-1 px-3",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
                    loading: _ctx.loading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.textButtonClose), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"]),
                  (_ctx.textButton)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        class: _normalizeClass(`${_ctx.classButton} ml-1 px-3`),
                        width: "120",
                        variant: "flat",
                        color: "secondary",
                        loading: _ctx.loading,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('eventSuccess')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.textButton), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "loading"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class", "height", "fullscreen", "modelValue", "persistent", "max-width"]))
}