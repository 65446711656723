
import { Options, Vue } from 'vue-class-component';
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { getErrorCatch, formatCurrency, sleep } from '../../utils';
import { convertTo12HourFormat, formatDateCompleted, getDate } from '@/filters/formatDate'
import { usePlanningStore } from '@/store/modules/planning';
import ModalCommon from '../common/ModalCommon.vue';
import { PlanningTripsResponse, PlanningTripsStationsResponse, PlanningTripDriverResponse } from '@/types/response/planning'
import { PlanningTripAPI } from '@/api/planning/index'
import CalendarPlanning from './CalendarPlanning.vue';
import { CrudEntity } from '@/types/common/api';
import { useMainStore } from '@/store/modules/main';
import AddDaysTrip from './AddDaysTrip.vue';


@Options({
  name: 'TablePlanning',
  components: {
    ModalCommon,
    CalendarPlanning,
    AddDaysTrip
  }
})
export default class TablePlanning extends Vue {
  private planningStore = usePlanningStore();
  private mainStore = useMainStore();
  private planningTripAPI = new PlanningTripAPI();
  public formatDateCompleted = formatDateCompleted;
  public convertTo12HourFormat = convertTo12HourFormat;
  public formatCurrency = formatCurrency
  public getDate = getDate;
  public loadingTable = false;
  public serverOptions: ServerOptions = {
    page: 1,
    rowsPerPage: 5
  }
  public visibleStations = false;
  public stations: PlanningTripsStationsResponse[] = []

  public visibleCalendar = false;
  public selectedPlanning: PlanningTripsResponse | null = null;
  public loadingSeatings = false;
  public idGeneralPlanning = ''
  public timeoutReloadDelete = undefined;
  public isVisibleAddTrip = false

  closeModalAddDays() {
    this.isVisibleAddTrip = false;
    this.selectedPlanning = null;
  }

  public headers: Header[] = [
    { text: "Nombre", value: "name" },
    { text: "Salida", value: "hourSalida" },
    { text: 'Llegada', value: 'hourLlegada' },
    { text: "Fechas", value: "days" },
    { text: "Ruta", value: "route.nameRoute" },
    { text: "Conductor", value: "driver" },
    { text: "Vehículo", value: "vehicle" },
    { text: '', value: 'actions' }
  ];


  get total() {
    return this.planningStore.totalPlanningTrips
  }

  get items() {
    return this.planningStore.planningTrips
  }

  get drivers() {
    return this.planningStore.drivers
  }

  created() {
    this.$watch('serverOptions', ({ page, rowsPerPage }: ServerOptions) => {
      this.planningStore.setPage(page);
      this.planningStore.setLimit(rowsPerPage);
      this.getPlanningTrips();
    })
  }

  mounted(): void {
    this.getPlanningTrips()
  }

  get calendar() {
    return this.planningStore.calendarData;
  }

  async getPlanningTrips() {
    this.loadingTable = true
    try {
      await this.planningStore.getPlanningTrips();
    } catch (error) {
      getErrorCatch(error)
    }
    this.loadingTable = false;
  }
  closeModalStations() {
    this.visibleStations = false;
  }

  getStationsPlanning(stations: PlanningTripsStationsResponse[], _id: string) {
    this.stations = stations;
    this.visibleStations = true;
    this.idGeneralPlanning = _id
  }

  toggleHourInit(station: PlanningTripsStationsResponse, editable = true, loading = false) {
    this.stations = this.stations.map((stationOld) => {
      return {
        ...stationOld,
        hourInitCrud: station._id == stationOld._id ? {
          newValueEditable: stationOld.hourInit,
          editable,
          loading
        } : stationOld.hourInitCrud
      }
    })
  }

  toggleDuration(station: PlanningTripsStationsResponse, editable = true, loading = false) {
    this.stations = this.stations.map((stationOld) => {
      return {
        ...stationOld,
        durationCrud: station._id === stationOld._id ? {
          newValueEditable: editable ? stationOld.durationReal as string : '',
          editable,
          loading
        } : undefined
      }
    })
  }

  validHour(hourEntry: string) {
    const hourList = hourEntry.split(':')
    if (hourList.length !== 2) return false;
    const hourString = hourList[0];
    const minString = hourList[1];
    if (minString.length !== 2 || hourString.length !== 2) return false
    const hourInt = parseInt(hourString);
    const minutesInit = parseInt(minString);
    if (isNaN(hourInt)) return false;
    if (isNaN(minutesInit)) return false;
    if (hourInt < 0 || hourInt > 23) return false;
    if (minutesInit < 0 || minutesInit > 59) return false;
    return true
  }

  async saveEditableHourInit(station: PlanningTripsStationsResponse) {
    if (!station.hourInitCrud) return
    if (!this.validHour(station.hourInitCrud.newValueEditable)) {
      this.mainStore.setNotification({
        isOpen: true,
        message: 'Hora inválida, formato correcto: HH:MM',
        color: 'red darken-3'
      })
      return;
    }
    this.toggleHourInit(station, true, true)
    try {
      await this.planningTripAPI.updateHourInitStation(station._id, station.hourInitCrud.newValueEditable)
      await this.getPlanningTrips();
      const planning = this.items.find(({ _id }) => _id == this.idGeneralPlanning)
      if (planning) {
        this.stations = planning.stations;
      }
    } catch (error) {
      getErrorCatch(error)
    }
    this.toggleHourInit(station, false, false)
  }

  async saveEditableDuration(station: PlanningTripsStationsResponse) {
    if (!station.durationCrud) return
    this.toggleDuration(station, true, true)
    try {
      await this.planningTripAPI.updateDurationStation(station._id, parseInt(station.durationCrud.newValueEditable))
      await this.getPlanningTrips();
      const planning = this.items.find(({ _id }) => _id == this.idGeneralPlanning)
      if (planning) {
        this.stations = planning.stations;
      }
      this.toggleDuration(station, false, false);
    } catch (error) {
      getErrorCatch(error)
    }
    this.toggleDuration(station, false, false)
  }

  async reloadCalendar(id: string) {
    this.closeModalCalendar()
    await sleep(300, this.timeoutReloadDelete)
    this.openModalCalendar(id)
  }

  openAddDaysTrip(value : string){
    const planning = this.items.find(({ _id }) => _id === value);
    if (!planning) return;
    this.selectedPlanning = planning;
    this.isVisibleAddTrip = true
  }

  async openModalCalendar(value: string) {
    const planning = this.items.find(({ _id }) => _id === value);
    if (!planning) return;
    this.loadingSeatings = true;
    this.planningStore.setLoadingPlanning(value, true);
    try {
      await this.planningStore.getCalendar(value);
      this.stations = await this.planningTripAPI.getStationsPlanningTrip(value);
      this.visibleCalendar = true;
      this.selectedPlanning = planning;

    } catch (error) {
      getErrorCatch(error)
    }
    this.planningStore.setLoadingPlanning(value, false);
    this.loadingSeatings = false;
  }
  closeModalCalendar() {
    this.visibleCalendar = false;
    this.selectedPlanning = null;
  }
  updateNamePlanning(_id: string) {
    this.planningStore.updateNameCRUD(_id)
  }
  updateDriver(_id: string) {
    this.planningStore.updateDriverCRUD(_id);
  }
  resetUpdateName(_id: string) {
    this.planningStore.updateNameCRUD(_id, 'reset');
  }
  async saveUpdateName(nameCrud: CrudEntity, name: string, _id: string) {
    if (nameCrud.newValueEditable === name) {
      this.resetUpdateName(_id)
      return;
    }
    this.planningStore.updateNameCRUD(_id, 'loading', true)
    try {
      await this.planningTripAPI.updateNamePlanning(_id, nameCrud.newValueEditable);
      this.resetUpdateName(_id)
      await this.getPlanningTrips();
    } catch (error) {
      getErrorCatch(error)
    }
    this.planningStore.updateNameCRUD(_id, 'loading', false)
  }
  resetUpdate(_id: string) {
    this.planningStore.updateDriverCRUD(_id, 'reset');
  }
  async saveUpdateDriver(driver: PlanningTripDriverResponse, _id: string) {
    if (driver._id === driver.newValueEditable) {
      this.resetUpdate(_id)
      return;
    }
    this.planningStore.updateDriverCRUD(_id, 'loading', true)
    try {
      await this.planningTripAPI.updateDriverPlanning(driver.newValueEditable, _id)
      this.resetUpdate(_id);
      await this.getPlanningTrips();
    } catch (error) {
      getErrorCatch(error)
    }
    this.planningStore.updateDriverCRUD(_id, 'loading', false)
  }

  setAllDays(_id: string, allDays: boolean) {
    this.planningStore.setAllDays(_id, !allDays)
  }
}
