import { defineStore } from "pinia";
import { GetTripResponse } from "@/types/response/trip";
import { TripApi } from "@/api/trip";
import { GetTripsPagination } from "@/types/request/trip";

interface TripsStateStore {
  trips: GetTripResponse[];
  paginationTrips: GetTripsPagination;
  totalTrips: number;
}

const tripApi = new TripApi();

export const useTripStore = defineStore("trips", {
  // other options...
  state: (): TripsStateStore => ({
    trips: [],
    paginationTrips: {
      limit: 5,
      page: 1,
      code: undefined,
      customer: undefined,
      warningTrip: undefined,
    },
    totalTrips: 1,
  }),
  actions: {
    setPage(page: number) {
      this.paginationTrips.page = page;
    },
    setLimit(limit: number) {
      this.paginationTrips.limit = limit;
    },
    setPlanningID(planning?: string) {
      this.paginationTrips.planning = planning;
    },
    async getTrips() {
      const { items, total } = await tripApi.getTrips(this.paginationTrips);
      this.trips = items.map((item) => {
        if(!item.seatings) return item
        const seatings = item.seatings.sort((a, b) => {
          const seatingA = parseInt(a.seating);
          const seatingB = parseInt(b.seating);
          return seatingA - seatingB;
        }).map((seating,index)=>{
          if(item.typeTrip == 'round' && item.returnData){
            const seatingReturnFinal = [item.returnData.seatingOwner, ...item.returnData.passengers]
            return {
              ...seating,
              returnSeating: seatingReturnFinal[index]
            }
          }
          return seating
        })
        return {
          ...item,
          seatings,
        };
      });
      this.totalTrips = total;
    },
    setCustomer(customer?: string) {
      this.paginationTrips.customer = customer;
    },
    setCode(code?: string) {
      this.paginationTrips.code = code;
    },
    sortCode() {
      this.trips.sort(compareCodes);
    },
    sortDate() {
      this.trips.sort(compareDates);
    },
    setDates(dateInit?: string, dateFinish?: string) {
      this.paginationTrips.dateFinish = dateFinish;
      this.paginationTrips.dateInit = dateInit;
    },
    setWarningTrip(value: boolean) {
      this.paginationTrips.warningTrip = value;
    },
  },
});
function compareDates(a: GetTripResponse, b: GetTripResponse) {
  if (!a.dateExecution || !b.dateExecution) return 0;
  const dateA = new Date(a.dateExecution);
  const dateB = new Date(b.dateExecution);
  return dateB.getTime() - dateA.getTime();
}

function extractYearAndCount(code: string) {
  const match = code.match(/(\d{4})-(\d+)$/);
  if (match) {
    return {
      year: parseInt(match[1], 10),
      count: parseInt(match[2], 10),
    };
  }
  return null;
}
function compareCodes(a: GetTripResponse, b: GetTripResponse) {
  const aParts = extractYearAndCount(a.code);
  const bParts = extractYearAndCount(b.code);

  if (!aParts || !bParts) return 0;
  if (aParts.year !== bParts.year) {
    return aParts.year - bParts.year;
  }
  return bParts.count - aParts.count;
}
