
import { Options, Vue } from 'vue-class-component';
import type { Header, ServerOptions } from "vue3-easy-data-table";
import { getErrorCatch, getStatusFLOW, formatCurrency, validateEmail, requiredField, getDayString } from '../../utils';
import { formatDateCompleted, getDate, convertTo12HourFormat, formatDateNoonTimestamp } from '@/filters/formatDate'
import { useTripStore } from '@/store/modules/trips';
import { GetStatusTripResponsePassenger, GetTripPaymentResponse, GetStatusTripResponseOwner, GetReturnTrip } from '@/types/response/trip';
import Passengers from './Passenger.vue';
import { mergeProps } from 'vue'
import { TypeResend } from '@/types/request/trip'
import { TripApi } from '@/api/trip';
import ModalCommon from '../common/ModalCommon.vue';
import { FormVuetify, SelectData } from '@/types/common/vuetify';
import { useMainStore } from '@/store/modules/main';
import { PlanningTripAPI } from '@/api/planning';


@Options({
    name: 'TableTrips',
    components: {
        Passengers,
        ModalCommon
    }
})
export default class TableTrips extends Vue {
    private tripStore = useTripStore();
    public days: Date[] = [];
    private tripApi = new TripApi();
    private planningTripAPI = new PlanningTripAPI()
    private mainStore = useMainStore()
    public formatDateCompleted = formatDateCompleted;
    public convertTo12HourFormat = convertTo12HourFormat;
    public getDate = getDate;
    mergeProps = mergeProps
    requiredField = requiredField;
    public getStatusFLOW = getStatusFLOW;
    public formatCurrency = formatCurrency;
    public visiblePassengers = false;
    public loadingTable = false;
    public mergePassengers: GetStatusTripResponsePassenger[] = []
    public fullNameCustomer = ""
    public daysFilter = ""
    public codeTrip = "";
    public emailResend = '';
    public planningSelected = '';
    public planningQuery = ''
    public valueWarning = false;
    public validateEmail = validateEmail;
    public planningsName: SelectData[] = []
    public serverOptions: ServerOptions = {
        page: 1,
        rowsPerPage: 5
    }
    public activeOtherEmail = false;
    public tripCodeSelected: string | null = null

    public headers: Header[] = [
        { text: "Fec. de compra", value: "dateCreated" },
        { text: "Viaje", value: "code" },
        { text: "Planificación", value: "planningTrip" },
        { text: "Comprador", value: "owner" },
        { text: "Fecha de viaje", value: "dateExecution" },
        { text: "Salida", value: "stationOrigin" },
        { text: "Llegada", value: "stationDestination" },
        { text: 'Pasajeros', value: 'passengers' },
        { text: 'Cupón', value: 'coupon' },
        { text: 'Pago', value: 'payment' },
        { text: '', value: 'actions' }
    ];


    get total() {
        return this.tripStore.totalTrips
    }

    get items() {
        return this.tripStore.trips.map((trip) => {
            return {
                ...trip,
                countPassenger: trip.passengers.length + 1
            }
        })
    }

    get customerQuery() {
        return this.tripStore.paginationTrips.customer
    }

    get codeQuery() {
        return this.tripStore.paginationTrips.code
    }
    created() {
        this.$watch('serverOptions', ({ page, rowsPerPage }: ServerOptions) => {
            this.tripStore.setPage(page);
            this.tripStore.setLimit(rowsPerPage);
            this.getTrips();
        })
    }

    async mounted() {
        this.getTrips()
        try {
            const plannings = await this.planningTripAPI.getSelectPlanning();
            this.planningsName = plannings.map(({ _id, name }) => {
                return {
                    title: name,
                    value: _id
                }
            });
        } catch (error) {
            getErrorCatch(error)
        }
    }

    async getTrips() {
        this.loadingTable = true
        try {
            await this.tripStore.getTrips();
        } catch (error) {
            console.log(error)
            getErrorCatch(error)
        }
        this.loadingTable = false;
    }
    getTaxes(payment: GetTripPaymentResponse) {
        const taxes = payment?.taxes || 0;
        const fee = payment?.fee || 0;
        return formatCurrency(taxes + fee);
    }

    viewPassenger(passenger: GetStatusTripResponsePassenger[], { firstName, lastName, rut, phone }: GetStatusTripResponseOwner, seatingOwner: string, typeTrip: string, returnData: GetReturnTrip) {
        this.mergePassengers = [];
        this.mergePassengers = [...passenger];
        if (typeTrip == 'round') {
            const seatingsReturn = returnData.passengers
            this.mergePassengers = this.mergePassengers.map((seating, key) => ({
                ...seating,
                returnSeating: seatingsReturn[key]
            }))
        }
        this.mergePassengers.push({
            _id: '',
            firstName,
            lastName,
            rut,
            seating: seatingOwner,
            phone,
            returnSeating: typeTrip == 'round' ? returnData.seatingOwner : undefined
        })
        this.visiblePassengers = true
    }
    closePassenger() {
        this.visiblePassengers = false;
        this.mergePassengers = []
    }
    setFullName() {
        if (this.fullNameCustomer == '') return;
        this.tripStore.setCustomer(this.fullNameCustomer);
        this.fullNameCustomer = '';
        this.getTrips();
    }
    setCode() {
        if (this.codeTrip == "") return;
        this.tripStore.setCode(this.codeTrip);
        this.codeTrip = "";
        this.getTrips()
    }
    removeCode() {
        this.tripStore.setCode(undefined);
        this.codeTrip = '';
        this.getTrips()
    }
    removeFullName() {
        this.tripStore.setCustomer(undefined);
        this.fullNameCustomer = '';
        this.getTrips()
    }

    async resendEmail(code: string | null, typeResend: TypeResend) {
        if (!code) return;
        if (typeResend === 'OTHERS') {
            const form = this.$refs['emailTrip'] as FormVuetify;
            const valid = await form.validate();
            if (!valid.valid) {
                return;
            }
        }
        this.loadingTable = true;
        try {
            await this.tripApi.resendEmail({ code, typeResend, email: typeResend === 'OTHERS' ? this.emailResend : undefined })
            this.mainStore.setNotification({
                isOpen: true,
                message: 'Correo enviado',
                color: 'green darken-2'
            })
            this.closeOtherModal();
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingTable = false;
    }

    openOtherModal(code: string) {
        this.activeOtherEmail = true;
        this.tripCodeSelected = code;
    }

    closeOtherModal() {
        this.activeOtherEmail = false
        this.tripCodeSelected = null;
    }
    setDateFilter() {
        this.$nextTick(() => {
            this.days[0].setHours(12)
            this.days[1].setHours(12)
            const dayInit = formatDateNoonTimestamp(this.days[0].getTime()).toMillis()
            const dayFinish = formatDateNoonTimestamp(this.days[1].getTime()).toMillis()
            this.tripStore.setDates(dayInit.toString(), dayFinish.toString())
            this.daysFilter = `${getDayString(this.days[0])} - ${getDayString(this.days[1])}`
            this.getTrips()
            this.days = []
        })
    }
    removeDays() {
        this.daysFilter = '';
        this.tripStore.setDates(undefined, undefined);
        this.getTrips();
    }
    setPlanning(value: string) {
        this.planningSelected = '';
        this.planningQuery = this.planningsName.find((names) => names.value == value)?.title || '';
        this.tripStore.setPlanningID(value);
        this.getTrips()
    }
    removePlanning() {
        this.planningQuery = '';
        this.tripStore.setPlanningID(undefined);
        this.getTrips();
    }
    updateWarning() {
        this.tripStore.setWarningTrip(this.valueWarning);
        this.getTrips();
    }
}
