
import { Vue, Options } from "vue-class-component";
import HeaderCommonBooking from "./HeaderCommonBooking.vue";
import { usePlanningStore } from "@/store/modules/planning";
import { CustomerSeating } from "@/types/request/planning";
import {
  formatRutChile,
  validateEmail,
  requiredField,
  validateNumber,
  ruleValidateRut,
  maxItem,
  repeatEmail,
  getErrorCatch,
} from "@/utils";
import { FormVuetify } from "@/types/common/vuetify";
import { useDisplay } from "vuetify";
import { customScrollTo, sleep } from "@/utils";
import { UserApi } from "@/api/users";
import { useMainStore } from "@/store/modules/main";
import { useAuthStore } from "@/store/modules/auth";
@Options({
  name: "Passengers",
  components: {
    HeaderCommonBooking,
  },
})
export default class Passengers extends Vue {
  public planningStore = usePlanningStore();
  private userApi = new UserApi();
  private mainStore = useMainStore();
  private authStore = useAuthStore();
  validateEmail = validateEmail;
  requiredField = requiredField;
  ruleValidateRut = ruleValidateRut;
  maxItem = maxItem;
  repeatEmail = repeatEmail;
  public repeatEmailValue = "";
  public blockedSeating: string[] = [];
  public dataOwner: CustomerSeating = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "+569",
    rut: "",
    seating: "",
  };
  public mobile = false;
  public timeOut = 0;
  public loaderUserRUT = false;
  public isExistUser: boolean | null = null;
  public urlSign =
    "https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=4d89bf2f-fa04-4d99-89e6-61dfd5d4c106&env=na4&acct=47495288-4253-493c-817e-a4d18677c408&v=2";
  public isSignUser = false;
  public loadStep = false;
  public isActiveUser: boolean | null = null;

  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
  }

  get isLogged() {
    return this.authStore.isLogged;
  }

  get passengers() {
    return this.planningStore.passengers;
  }
  get passengersFinal() {
    return this.planningStore.passengersFinal;
  }

  get owner() {
    return this.planningStore.owner;
  }

  get ownerFinal() {
    return this.planningStore.ownerFinal;
  }

  get typeTrip() {
    return this.planningStore.searchSelected?.typeTrip || "round";
  }

  get allSeatings() {
    const seatings = [];
    if (this.owner) {
      seatings.push(this.owner.seating);
    }
    seatings.push(...this.passengers.map(({ seating }) => seating));
    return seatings.filter((seating) => {
      const seatingOwner = this.dataOwner.seating;
      return seating != seatingOwner;
    });
  }

  back() {
    this.planningStore.updateStageSeating("outbound");
    this.planningStore.setStep("EMPTY");
  }

  async nextTo() {
    try {
      const form = this.$refs["formPassenger"] as FormVuetify;
      if (!form) return;
      const valid = await form.validate();
      if (valid.valid) {
        if (!this.owner) throw new Error("Datos incompletos");
        await this.format("owner");
        if (this.isExistUser) {
          if (this.isActiveUser) {
            this.planningStore.setStep("PAYMENT");
          } else {
            this.mainStore.setNotification({
              isOpen: true,
              color: "red-darken-3",
              message: "Usuario inactrivo.",
              timeout: 4000,
            });
          }
          //this.planningStore.setStep('PAYMENT')
        } else {
          this.loadStep = true;
          await this.userApi.createCustomer({
            firstName: this.owner.firstName,
            email: this.owner.email,
            rut: this.owner.rut,
            lastName: this.owner.lastName,
            phone: "+596" + this.owner.phone,
            address: "",
            commune: "",
          });
          this.loadStep = false;
          this.isExistUser = true;
        }
      }
      if (this.mobile) {
        await sleep(300, this.timeOut);
        customScrollTo("null", 0);
      }
    } catch (error) {
      this.loadStep = false;
      getErrorCatch(error);
    }
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  async format(type: "owner" | "normal", passenger?: CustomerSeating) {
    if (type === "owner" && this.owner) {
      this.owner.rut = formatRutChile(this.owner.rut);
      this.isActiveUser = null;
      this.loaderUserRUT = true;
      try {
        if (this.owner.rut.length > 3) {
          const user = await this.userApi.getCustomerByRUT(this.owner.rut);
          this.owner.firstName = user.firstName;
          this.owner.lastName = user.lastName;
          this.owner.email = user.email || "";
          this.owner.phone = user.phone || "";
          this.repeatEmailValue = user.email || "";
          this.isExistUser = true;
          this.isActiveUser = user.status === "ACTIVE";
        }
      } catch (error) {
        //getErrorCatch(error);
        if (!this.authStore.isLogged) {
          this.isExistUser = false;
          this.isActiveUser = null;
        } else {
          this.isExistUser = true;
          this.isActiveUser = true;
        }
      }
      this.loaderUserRUT = false;
    }
    if (type === "normal" && passenger) {
      passenger.rut = formatRutChile(passenger.rut);
      passenger.isLoading = true;
      try {
        if (passenger.rut.length > 3) {
          const user = await this.userApi.getCustomerByRUT(passenger.rut);
          passenger.firstName = user.firstName;
          passenger.lastName = user.lastName;
          passenger.isLoading = false;
        }
      } catch (error) {
        console.log("Usuario no encontrado");
      }
      passenger.isLoading = false;
    }
    //this.dataForm.rut = formatRutChile(this.dataForm.rut)
  }
  validateNumber = validateNumber;
  formatReverse(type: "owner" | "normal", passenger?: CustomerSeating) {
    if (type === "owner" && this.owner) {
      this.owner.rut = this.owner.rut.replace(/[^\dKk]/g, "");
    }
    if (type === "normal" && passenger) {
      passenger.rut = passenger.rut.replace(/[^\dKk]/g, "");
    }
  }

  loadData() {
    if (this.authStore.user && this.owner && this.authStore.user.rut) {
      this.owner.rut = formatRutChile(this.authStore.user.rut);
      this.format("owner");
    }
  }
}
