
import { convertTo12HourFormat, getDate } from '@/filters/formatDate';
import { useTripStore } from '@/store/modules/trips';
import { GetTripResponse } from '@/types/response/trip';
import { formatCurrency, getErrorCatch, translate } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import HeaderCommonBooking from '../Booking/HeaderCommonBooking.vue';
import ModalTripDetail from './ModalTripDetail.vue';
import ModalCommon from '../common/ModalCommon.vue';
import { useAuthStore } from '@/store/modules/auth';
import { SelectData } from '@/types/common/vuetify';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import ModalCancelTrip from './ModalCancelTrip.vue';
import MenuSeatings from './MenuSeatings.vue';
import TripHourRound from './TripHourRound.vue';
import ButtonsTrip from './ButtonsTrip.vue';
import { DateTime } from 'luxon';
import { useMainStore } from '@/store/modules/main';


@Options({
    components: {
        HeaderCommonBooking,
        ModalTripDetail,
        ModalCommon,
        ModalCancelTrip,
        MenuSeatings,
        TripHourRound,
        ButtonsTrip
    },
    name: 'MainCustomer'
})
export default class MainCustomer extends Vue {
    private tripStore = useTripStore();
    private authStore = useAuthStore()
    private mainStore = useMainStore();
    public getDate = getDate;
    public formatCurrency = formatCurrency;
    public convertTo12HourFormat = convertTo12HourFormat;
    public translate = translate;
    public loading = false;
    public dialog = false;
    public tripSelected: GetTripResponse | null = null
    public isVisibleCancelModal = false;
    public mobile = false;
    public sortedSelected = 'code';

    get dateNow(){
        return DateTime.fromJSDate(new Date()).setZone(this.mainStore.timeZone);
    }

    public sorted: SelectData[] = [{
        title: 'Código',
        value: 'code'
    }, {
        title: 'Fecha de viaje',
        value: 'dateExecution'
    }]

    mounted(): void {
        const { mobile } = useDisplay();
        this.mobile = mobile.value;
        this.loadTrips();
    }

    isLateHour(hour: string, dateExecutingTrip: string): boolean {
        const timeDeparture = hour.split(":");
        if (timeDeparture.length !== 2) return true;
        const hourDeparture = parseInt(timeDeparture[0]);
        const minuteDeparture = parseInt(timeDeparture[1]);
        const dateExecuting = DateTime.fromJSDate(new Date(dateExecutingTrip)).setZone(this.mainStore.timeZone).set({ hour: hourDeparture, minute: minuteDeparture, second: 0 }).minus({ hours: 1 });
        return dateExecuting.toMillis() < this.dateNow.toMillis();
    }

    get trips() {
        return this.tripStore.trips.map(trip => {
            return {
                ...trip,
                isLate: this.isLateHour(trip.hourDeparture, trip.dateExecution),
                isLateReturn: trip.typeTrip == 'round' && trip.returnData ? this.isLateHour(trip.returnData.hourDeparture, trip.returnData.dateExecuting || new Date(0).toString()) : false
            }
        })
    }

    async loadTrips() {
        this.loading = true;
        try {
            this.tripStore.setLimit(-1);
            await this.tripStore.getTrips();
            this.sortTrip(this.sortedSelected)
        } catch (error) {
            getErrorCatch(error)
        }
        this.loading = false;
    }
    openDialog(trip: GetTripResponse) {
        this.tripSelected = trip;
        this.dialog = true;
    }
    openCancelTrip(trip: GetTripResponse) {
        this.tripSelected = trip;
        this.isVisibleCancelModal = true;
    }
    closeDialog() {
        this.tripSelected = null;
        this.dialog = false
    }
    back() {
        this.$router.push('/booking')
    }
    closeCancelModal() {
        this.isVisibleCancelModal = false;
    }

    sortTrip(value: string) {
        switch (value) {
            case 'code':
                this.tripStore.sortCode()
                break;

            case 'dateExecution':
                this.tripStore.sortDate()
                break;
            default:
                break;
        }
    }

    async cancelTrip() {
        try {
            await this.loadTrips()
            await this.authStore.getUser()
        } catch (error) {
            getErrorCatch(error)
        }
    }

    getCancelTrip(trip: GetTripResponse) {
        if (trip.status == 'FAILED_PAYMENT') {
            return true;
        }
        if (trip.status === 'CANCEL') {
            if (trip.typeTrip !== 'round') {
                return true;
            }
            if (!trip.typeCancelTrip) {
                return false;
            }
            if (trip.typeCancelTrip.includes('BOTH')) {
                return true;
            }
            return trip.typeCancelTrip.includes('ONE_WAY') && trip.typeCancelTrip.includes('ONLY_RETURN')
        } else {
            return false;
        }
    }
}
