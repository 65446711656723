
import { VehicleAPI } from '@/api/vehicles';
import { CreateVehicleRequest } from '@/types/request/vehicle';
import { GetBrandsResponse, GetModelByBrandResponse, GetTypesVehicleResponse } from '@/types/response/vehicle';
import { getErrorCatch, requiredField, min } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import ModalCommon from '../common/ModalCommon.vue';
import { useVehicleStore } from '@/store/modules/vehicle';
import { FormVuetify } from '@/types/common/vuetify';
@Options({
    name: 'CreateVehicle',
    props: {
        isVisible: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    components: {
        ModalCommon
    }
})
export default class CreateVehicle extends Vue {
    public loadingCreateVehicle = false;
    private vehicleApi = new VehicleAPI()
    private vehicleStore = useVehicleStore();
    public isVisible!: boolean;
    public dataCreate: CreateVehicleRequest = {
        typeVehicle: '',
        patent: '',
        model: '',
        year: '',
        seatings: ''
    }
    public brandSelected = '';
    public loadingModels = false;

    public brands: GetBrandsResponse[] = [];
    public models: GetModelByBrandResponse[] = []
    public typesVehicle: GetTypesVehicleResponse[] = []
    closeModalCreateVehicle() {
        this.dataCreate = {
            typeVehicle: '',
            patent: '',
            model: '',
            year: '',
            seatings: ''
        }
        this.brandSelected = '';
        const form = this.$refs['formCreateVehicle'] as FormVuetify;
        if (!form) return;
        form.reset();
        this.$emit('closeModal')
    }
    async saveVehicle() {
        const form = this.$refs['formCreateVehicle'] as FormVuetify;
        if (!form) return;
        const validate = await form.validate();
        if (validate.valid) {
            this.loadingCreateVehicle = true;
            try {
                const data: CreateVehicleRequest = {
                    ...this.dataCreate,
                    year: parseInt(this.dataCreate.year as string),
                    seatings: parseInt(this.dataCreate.seatings as string)
                }
                await this.vehicleApi.createVehicle(data);
                await this.vehicleStore.getVehicles()
                this.closeModalCreateVehicle();
            } catch (error) {
                getErrorCatch(error)
            }
            this.loadingCreateVehicle = false;
        }
    }

    created() {
        this.$watch('isVisible', (value: boolean) => {
            if (value) {
                this.getBrands();
        this.getTypesVehicle();
            }
        })
    }

    mounted() {
        this.getBrands()
        this.getTypesVehicle();
    }

    async getBrands() {
        try {
            this.brands = await this.vehicleApi.getBrands()
        } catch (error) {
            getErrorCatch(error)
        }
    }

    async getTypesVehicle() {
        try {
            this.typesVehicle = await this.vehicleApi.getTypesVehicle()
        } catch (error) {
            getErrorCatch(error)
        }
    }

    async getModels(brand: string) {
        if (!brand) return;
        this.loadingModels = true;
        try {
            const models = await this.vehicleApi.getModelsByBrand(brand)
            this.models = models;
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingModels = false;
    }
    public requiredField = requiredField;
    public min = min;

}
