import { DateTime } from "luxon";
import { CrudEntity, IResponsePagination } from "../common/api";
import { TypeTrip } from "../request/trip";
import { GetReturnTrip } from "./trip";

export interface PlanningTripsResponse {
  _id: string;
  name: string;
  days: Date[] | string[];
  route: PlanningTripRouteResponse;
  driver: PlanningTripDriverResponse;
  vehicle: PlanningVehicleDriverResponse;
  dateCreated: Date;
  loading?:boolean;
  allDays?:boolean
  stations :PlanningTripsStationsResponse[];
  nameCRUD: CrudEntity
}

export interface TripTodayDriverResponse {
  dateExecuting: Date;
  hourDeparture: string;
  vehicle: PlanningVehicleDriverResponse;
  _id: string;
  countSeatings: number;
  planningTrip: {
    _id: string;
    name: string;
  };
  route: string;
  seatings: SeatingGetTripTodayDriver[];
  routes : PlanningTripsStationsResponse[];
  origin?: { name: string; hour: string };
  finish?: { name: string; hour: string };
  status : STATUS_ACTIVE_TRIP;
  loading?:boolean;
  statusIcon?:string;
  colorStatusIcon?:string
  colorStatusText?:string;
  returnData?:GetReturnTrip
  typeTrip?:  TypeTrip;
  dateTimeTrip?:DateTime
}

export interface PlanningTripsResponsePagination
  extends IResponsePagination<PlanningTripsResponse[]> {}

export interface PlanningTripDriverResponse extends CrudEntity {
  email: string;
  _id: string;
  fullname: string;
}

export interface PlanningTripRouteResponse {
  _id: string;
  nameRoute: string;
}

export interface PlanningVehicleDriverResponse {
  _id: string;
  patent: string;
  model: string;
  brand: string;
  seatings: number;
}

export interface PlanningTripsStationsResponse {
  _id: string;
  routeStation: {
    _id: string;
    duration: number;
    originStation: string;
    finalStation: string;
    keyOrder: number;
  };
  hourInit: string;
  hourFinish: string;
  price: number;
  durationReal : number | string;
  durationCrud ?: CrudEntity;
  hourInitCrud?:CrudEntity
}

export interface GetBookingResponse {
  _id: string;
  hourInit: string;
  hourFinish: string;
  price: number;
  planingTrip: string;
  namePlanningTrip : string;
  vehicle: {
    model: string;
    patent: string;
    year: number;
    seatings: number;
    typeVehicle: string;
    brand: string;
  };
  seatings: {
    _id: string;
    seating: string;
  }[];
  durationTrip: number;
  activeTrip: string;
}

export interface GetCalendarResponse {
  _id: string;
  dateExecuting: Date | string;
  seatings: GetCalendarSeating[];
  status : STATUS_ACTIVE_TRIP
}

export enum STATUS_SEATING {
  ATTENDED = "ATTENDED", // PRESENT
  ABSEND = "ABSEND", // AUSENCIA
  ON_HOLD = "ON_HOLD", // POR DEFECTO EN ESPERA
  POSTPONED = "POSTPONED", // POSTERGADO
}

export enum STATUS_ACTIVE_TRIP {
  ON_HOLD = 'ON_HOLD',
  IN_PROGRESS = 'IN_PROGRESS',
  FINALIZED = 'FINALIZED',
  POSTPONED = 'POSTPONED', // POSTERGADO,
  REMOVED = 'REMOVED'
}


export type TypeStatusSeating = "ATTENDED" | "ABSEND" | "POSTPONED" | "ON_HOLD";
export type TypeStatusTrip = 'ACTIVE' | 'REMOVED' | 'PAYMENT_PENDING' | 'FAILED_PAYMENT' | 'CANCEL';

export interface GetCalendarSeating {
  firstName: string;
  lastName: string;
  rut: string;
  phone?:string;
  seating: string;
  station: string;
  _id: string;
  status: TypeStatusSeating;
  origin?: { name: string; hour: string };
  finish?: { name: string; hour: string };
  loading?:boolean;
}

export interface SeatingGetTripTodayDriver {
  firstName: string;
  lastName: string;
  rut: string;
  seating: string;
  station: StationDataTripDriver;
  _id: string;
  status: TypeStatusSeating;
  loading?:boolean
}

export interface StationDataTripDriver {
  originStation: string;
  finalStation: string;
  hourInit:string;
  hourFinish : string
}


export interface SelectPlanning {
  _id : string;
  name : string;
}