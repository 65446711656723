export enum USER_ENDPOINTS {
  CREATE_GUEST = "create-customer",
  UPDATE_CUSTOMER = 'update-customer',
  CREATE_CUSTOMER = 'create-customerv2',
  GET_GUEST = "get-customers",
  GET_DRIVERS = "get-drivers",
  CREATE_DRIVER = "create-driver",
  GET_DRIVER_BYNAME = 'get-drivers/',
  GET_CUSTOMERS_BYNAME = 'get-customers/',
  GET_ALL_DRIVERS = 'get-all-driver',
  GET_CUSTOMER_RUT = 'get-customer/',
  ADD_CARD_FLOW = 'add-card',
  REFRESH_SIGN = 'refresh-sign/',
  RESEND_SIGN = 'resend-sign/',
  SIGN_USER = 'sign-user/',
  ACTIVE_USER = 'active-user/',
  DESACTIVE_USER = 'desactive-user/',
}
