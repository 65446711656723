
import { GetTripResponse } from '@/types/response/trip';
import { Options, Vue } from 'vue-class-component';
@Options({
    name: 'TripHourRound',
    props: {
        trip: {
            type: Object,
            default: () => ({}),
            required: false
        }
    },
    emits: ['openDialog', 'openCancelTrip']
})
export default class TripHourRound extends Vue {
    public trip !: GetTripResponse;

    openDialog() {
        this.$emit('openDialog', this.trip)
    }

    openCancelTrip() {
        this.$emit('openCancelTrip', this.trip)
    }
    validateTrip() {
        if (this.trip.isLate) {
            if (this.trip.typeCancelTrip?.includes('ONLY_RETURN')) {
                return true;
            } else {
                if (this.trip.isLateReturn) {
                    return true;
                }
                return false;
            }
        } else if (this.trip.isLateReturn) {
            if (this.trip.typeCancelTrip?.includes('ONE_WAY')) {
                return true;
            }
        } 
        return false 
    }
}
